import { forOwn, throttle } from 'lodash'
import pluralize from 'pluralize'
import moment from '~/composables/useMoment'
import Clamp from '~/orm/models/Clamp'
import { calcLoadFactor, formatValueWithUnit } from '~/utils/calculations'
import Unit from '~/orm/models/Unit'
import Preset from '~/orm/models/Preset'
import { calculateRequestEntitiesIds } from '~/utils/entities'
import Site from '~/orm/models/Site'
import { getScopeByCommodity, LAYOUT_OPTIONS_BY_COMMODITY } from '~/utils/commodities'
import { get1minResolutionForAPI } from '~/utils/resolution'

export const state = () => ({
    loading: false,
    items: [],
})

export const getters = {
    payload(_state, _getters, rootState, rootGetters) {
        const { period } = rootState
        const unit = Unit.find(rootGetters['clamp/unit'])
        const {
            tabIndex,
            selectedPoint,
            commodity,
        } = rootState.clamp
        const customerId = rootState.auth.user.customer_id
        const selectedClamps = rootGetters['clamp/selectedClamps']
        const { clampIds } = calculateRequestEntitiesIds(selectedClamps, commodity)
        const payload = {
            customerId,
            end: moment(period.end).subtract(1, 's').toISOString(),
            start: moment(period.start).toISOString(),
            resolution: period.resolution,
            circuitIds: [...clampIds, ...rootGetters['clamp/selectedClampsIds'].filter(e => e[0] === 'V')],
            timezone: rootGetters['period/timezone'],
            extraParams: [...(unit ? [unit] : []), ...rootGetters['clamp/selectedParameters']],
            selectedPoint: selectedPoint?.x,
        }

        if (tabIndex === '0') {
            // Home tab (normal clamps)
            const meters = {}
            selectedClamps.forEach(clamp => {
                if (clamp.virtual) return
                const { meter } = clamp.relationships
                if (!meter) return
                // group by meter
                const meterId = meter.meterId
                if (!meters[meterId]) {
                    meters[meterId] = {
                        gspGroup: meter.gspGroup,
                        clamps: [],
                        mpanTop: meter.mpanTop,
                        mpanBottom: meter.mpanBottom,
                        kVAThreshold: meter.kvaThreshold,
                    }
                }
                meters[meterId].clamps.push(clamp.id)
            })
            payload.meters = Object.values(meters)
        } else {
            // Virtual clamps tab
            let presets = []
            const presetsTree = rootGetters['clamp/virtualTreeViewItems'][0].children
            const activeEntities = rootState['entities-manager'].activeEntities.virtual
            const selectedEntities = rootState['entities-manager'].selectedEntities.virtual
            const euids = activeEntities.length > 0 ? activeEntities : selectedEntities
            if (euids.length) {
                presets = presetsTree
                    .map(item => {
                        const isSelected = euids.includes(item.euid)
                        const selectedChildren = item.children.filter(c => euids.includes(c.euid))
                            .flatMap(c => {
                                return ['sites', 'meters'].includes(c.entity)
                                    ? calculateRequestEntitiesIds([rootGetters['reports/entityModel'](c.entity).find(c.id)], commodity).clampIds
                                    : parseInt(c.$id)
                            })
                        if (!isSelected && !selectedChildren.length) return undefined

                        if (isSelected) {
                            selectedChildren.push(`V${item.$id}`)
                        }

                        return {
                            presetId: parseInt(item.$id),
                            clamps: [`V${item.$id}`, ...item.children.map(c => parseInt(c.$id))],
                            selected: selectedChildren,
                        }
                    })
                    .filter(v => v)
            }
            payload.presets = presets
        }
        return payload
    },
}

export const mutations = {
    SET_LOADING(state, loading) {
        state.loading = loading
    },

    SET_ITEMS(state, items = []) {
        state.items = items
    },
}

async function fetchItems({ commit, getters, rootState, rootGetters }) {
    const { period, clamp: { commodity, tabIndex } } = rootState
    const selectedClamps = rootGetters['clamp/selectedClamps']
    const timezone = rootGetters['period/timezone']
    if (!selectedClamps.length) {
        commit('SET_ITEMS', [])
        return
    }
    const currency = rootGetters.currency
    const clampIds = calculateRequestEntitiesIds(selectedClamps)
    const presetIds = selectedClamps.filter(({ virtual }) => virtual).map(({ id }) => Number(id.replace('V', '')))
    const res = await fetchFromAggregates(this.$api,
        {
            ...clampIds,
            presetIds,
            period,
            timezone,
            selectedPoint: getters.payload.selectedPoint,
            extraParameters: getters.payload.extraParams,
            commodity,
            currency,
        },
        tabIndex)
    commit('SET_ITEMS', res)
}

export async function fetchFromAggregates(
    api,
    { clampIds, siteIds, presetIds, period, timezone, selectedPoint, extraParameters = [], commodity, currency },
    tabIndex,
) {
    const { inspectorAggregate } = LAYOUT_OPTIONS_BY_COMMODITY(commodity)
    const extraKeys = extraParameters.map(({ key }) => key)
    const aggregatesSetMethod = Object.entries(inspectorAggregate).reduce((acc, [k, v]) => {
        if (!acc[k]) acc[k] = new Set()
        v.forEach(key => acc[k].add(key))
        if (k !== 'sum') extraKeys.forEach(key => acc[k].add(key))
        return acc
    }, {})
    for (const unit of extraParameters) {
        if (unit.isCumulative) {
            aggregatesSetMethod?.sum?.add(unit.key)
        }
    }
    if (!period) return []
    const daterange = selectedPoint
        ? [moment(selectedPoint).format(), moment(selectedPoint).addResolution(period.resolution).format()]
        : [moment(period.start).format(), moment(period.end).format()]

    const apiParams = {
        ...Object.fromEntries(Object.entries(aggregatesSetMethod).map(([k, v]) => [k, [...v]])),
        clampIds,
        siteIds,
        presetIds,
        resolution: get1minResolutionForAPI(period.resolution),
        daterange,
        splitCircuits: true,
        timezone,
        scopes: [getScopeByCommodity(commodity).short],
    }
    const { units, data } = await api.clickHouse.fetchAggregates(apiParams)
    const _items = []
    if (!units) return _items

    const commonParameters = Object.keys(units).reduce((acc, k) => {
        if (!acc[k]) acc[k] = Unit.find(k)
        return acc
    }, {})

    for (const [entity, entityData] of Object.entries(data ?? {})) {
        for (const [entityId, responseData] of Object.entries(entityData ?? {})) {
            let entityModel
            if (entity === 'clamps') {
                entityModel = Clamp.find(entityId)
            } else if (entity === 'meters') {
                entityModel = Clamp.query().where('meterId', Number(entityId)).where('isMainIncomer', true).first()
            } else if (entity === 'presets') {
                entityModel = Preset.find(entityId)
            } else if (entity === 'sites') {
                entityModel = Site.find(entityId)
            }
            const isPower = commodity === 'power'
            if (entity === 'sites' && tabIndex === '1') {
                _items.push(isPower ? powerTransformData(responseData, entityModel, period, commonParameters) : otherCommodityTransformData(responseData, entityModel, commonParameters, currency))
            } else {
                _items.push(isPower ? powerTransformData(responseData, entityModel, period, commonParameters) : otherCommodityTransformData(responseData, entityModel, commonParameters, currency))
            }
        }
    }
    return _items
}

function otherCommodityTransformData(data, entity, units, currency) {
    const values = {}
    forOwn(data, (fields, type) => {
        forOwn(fields, (e, unit) => {
            const unitData = units[unit]
            const value = e.value
                ? e.value.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })
                : 0
            const unitNameTransKey = `units.${unitData.id}.name`
            const typeTransKey = `abbreviation.${type}`
            const valueKey = `${window.$nuxt.$t(unitNameTransKey)} ${window.$nuxt.$t(typeTransKey)}`
            values[valueKey] = {
                ...e,
                value: unitData.symbol === currency.symbol ? `${unitData.symbol} ${value}` : `${value} ${unitData.symbol}`,
            }
        })
    })

    const id = entity.entity === 'presets' ? `V${entity.id}` : entity.id
    const item = {
        meterId: entity.meterId,
        presetId: entity.presetId,
        id,
        [`${pluralize.singular(['clamps', 'presets'].includes(entity.entity) ? 'pipes' : entity.entity)}Id`]: `${id}`,
        name: entity.getName(),
        ...values,
        ...data,
    }
    if (!entity.isMainIncomer && entity.parent?.meterName) {
        const meterNameKey = window.$nuxt.$t('label.meter_name')
        item[meterNameKey] = entity.parent.meterName
    }
    if (entity.parent?.parent?.name) {
        const siteNameKey = window.$nuxt.$t('label.site_name')
        item[siteNameKey] = entity.parent.parent?.name
    }

    return { ...item }
}

function powerTransformData(data, entityModel, period, commonParameters) {
    const entity = entityModel.entity
    return {
        mpanBottom: entityModel.mpanBottom || entityModel.meter?.mpanBottom,
        id: entity === 'presets' ? `V${entityModel.id}` : entityModel.id,
        entity,
        period: `${moment(period.start).format('MMM DD, HH:mm, YYYY')} - ${moment(period.end).subtract(1, 's').format('MMM DD, HH:mm, YYYY')}`,
        start: period.start,
        end: moment(period.end).subtract(1, 's'),
        kvaCapacity: formatValueWithUnit((entityModel.kvaThreshold || entityModel.meter?.kvaThreshold) * 1e3, commonParameters.S),
        totalCons: formatValueWithUnit(data.sum.E.value, commonParameters.E),
        totalCarbon: formatValueWithUnit(data.sum.U_CO2E.value, commonParameters.U_CO2E),
        avgKw: formatValueWithUnit(data.avg.P.value, commonParameters.P),
        maxDemand: formatValueWithUnit(data.max.P.value, commonParameters.P),
        maxDemandTime: data.max.P.atMillis,
        loadFactor: calcLoadFactor(data.max.P.value, data.sum.E.value, moment(period.end).diff(moment(period.start), 'hours')),
        avgPf: data.avg.PF.value,
        minPf: data.min.PF.value,
        avgKva: formatValueWithUnit(data.avg.S.value, commonParameters.S),
        peakKva: formatValueWithUnit(data.max.S.value, commonParameters.S),
        peakKvaTime: data.max.S.atMillis,
        peakKvar: formatValueWithUnit(data.max.Q.value, commonParameters.Q),
        avgKvar: formatValueWithUnit(data.avg.Q.value, commonParameters.Q),
        kvarh: formatValueWithUnit(data.sum.RE.value, commonParameters.RE),
        capacityCost: formatValueWithUnit(data.sum.C_DUXCAP.value, commonParameters.C),
        excessCapacityUnits: formatValueWithUnit(data.sum.U_DUXCAP.value, commonParameters.U_DUXCAP),
        reactiveUnits: formatValueWithUnit(data.sum.U_DURE.value, commonParameters.U_DURE),
        reactiveCost: formatValueWithUnit(data.sum.C_DURE.value, commonParameters.C),
        totalCost: formatValueWithUnit(data.sum.C.value, commonParameters.C),
        ...data,
    }
}

export const actions = {
    fetchItems: throttle(fetchItems, 500, { leading: false }),

    download({ rootState, rootGetters }, payload) {
        payload.use_friendly_names = rootState['entities-manager'].showFriendlyName
        payload.useFriendlyNames = rootState['entities-manager'].showFriendlyName
        payload.timezone = rootGetters['period/timezone']
        return this.$api.electricity.downloadInspectorData(payload)
    },
}
