import { TREE_ITEMS_COMMODITY_STATE } from '~/utils/commodities'

export default () => ({
    initialised: null,
    loading: false,
    loadingLevel: 0,
    tabIndex: '0',
    treeviews: [],
    selectedUnit: null, // 'cm',
    selectedPoint: null,
    autoYAxis: false,
    defaultAutoYAxis: false,
    triadsReadings: [],
    loadFactorData: null,
    ragEnabled: false,
    analysisFeatures: {
        maxDemands: {
            active: false,
            name: 'Max Demands',
            description: '',
            type: 'annotation',
        },
        loadFactor: {
            active: false,
            name: 'Load factor',
            description: '',
            type: 'flag',
        },
        averageLines: {
            active: false,
            name: 'Average lines',
            description: '',
        },
        peakTimes: {
            active: false,
            name: 'Peak times',
            description: '',
            type: 'annotation',
        },
        triads: {
            active: false,
            name: 'Triads',
            description: '',
            type: 'annotation',
        },
    },
    treeItems: TREE_ITEMS_COMMODITY_STATE,
    pit: false,
    drillDown: {
        unit: '',
        entity: '',
        entityIds: [],
        entityNames: [],
        previousPeriod: {},
        period: {},
    },
    consolidation: false,
    annotationsMode: false,
    comparison: null,
    selectedParametersPreset: null,
    maxSelectableParameters: 4,
    maxSelectableClampsWhenMultiParameter: 2,
    tracesVisibility: {},
    isInspectorVisible: false,
    commodity: null,
    selectedMeterContracts: [],
    selectedParameterKeys: [],
    snapshots: [],
    scope: null,
    maxSelectableClamps: 32,
})
