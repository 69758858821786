
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { eliberate } from '~/utils/state'
import DialogHeader from '~/components/dialogs/header'
/** Mixins */
import PasswordMixin from '~/mixins/passwordMixin'
import Unit from '~/orm/models/Unit'
import SelectAppMode from '~/components/widgets/select-app-mode'
import { VALID_RESOLUTIONS } from '~/utils/resolution'
import { days } from '~/utils/date'

export default {
    name: 'SettingsDialog',
    components: { SelectAppMode, DialogHeader },
    mixins: [PasswordMixin],

    props: {
        labels: {
            type: Object,
            default: () => ({
                changelog: 'Welcome Prompt',
                showGetStarted: 'Get Started Prompt',
                changeUnits:
                    'Automatically change display units based on energy use. Low consumption will appear as "Wh" or "kWh", high consumption as "MWh" or "GWh".',
            }),
        },
        showAvatar: Boolean,
    },

    data: () => ({
        tab: null,
        activeTab: null,
        value: false,
        settings: null,
        user: null,
        loading: false,
        show: {
            password: false,
            passwordConfirm: false,
            oldPassword: false,
        },
        initialised: false,
        hasChanges: false,
    }),

    validations() {
        return {
            ...PasswordMixin.validations,
            user: {
                firstName: {
                    required,
                    minLength: minLength(2),
                    maxLength: maxLength(50),
                },
                lastName: {
                    required,
                    minLength: minLength(2),
                    maxLength: maxLength(50),
                },
            },
        }
    },

    computed: {
        languages() {
            return [
                {
                    value: 'en',
                    text: 'English',
                },
                {
                    value: 'fr',
                    text: 'French',
                },
            ]
        },
        timeZones() {
            return [
                'Europe/London',
                'Europe/Malta',
                'Europe/Paris',
                'Europe/Warsaw',
            ]
        },
        currencies() {
            return ['EUR', 'GBP', 'PLN']
        },
        months() {
            return this.$moment.months().map((text, idx) => ({ text, value: idx + 1 }))
        },
        days() {
            return days(this.settings.reporting.financialYearStartMonth - 1)
        },
        weekDays() {
            return this.$moment.weekdays().map((text, idx) => ({ text, value: idx === 0 ? 7 : idx }))
        },
        avatar() {
            return (
                String(this.user.firstName).charAt(0).toUpperCase() + String(this.user.lastName).charAt(0).toUpperCase()
            )
        },
        passwordConfirmErrors() {
            let errors = []
            if (this.$v.password.passwordConfirm.$error && !this.$v.password.passwordConfirm.sameAsPassword) {
                errors = ['Password confirmation must be the same as the password.']
            }
            return errors
        },
    },

    watch: {
        value(val) {
            if (val) {
                this.init()
            }
        },
        'password.password': {
            handler(value) {
                this.modelHasChanged({ key: 'password.password', value })
            },
        },
        'password.passwordConfirm': {
            handler(value) {
                this.modelHasChanged({ key: 'password.passwordConfirm', value })
            },
        },
        settings: {
            deep: true,
            handler(value) {
                this.modelHasChanged({ key: 'settings', value })
            },
        },
        user: {
            deep: true,
            handler(value) {
                this.modelHasChanged({ key: 'user', value })
            },
        },
        'settings.reporting.financialYearStartMonth'() {
            if (this.settings.reporting.financialYearStartDay && !this.days.includes(this.settings.reporting.financialYearStartDay)) {
                this.settings.reporting.financialYearStartDay = 1
            }
        },
    },

    mounted() {
        window.__S = this
    },

    methods: {
        modelHasChanged({ key, value }) {
            if (this.initialised) {
                this.hasChanges = true
            }
        },
        toggle() {
            this.value = !this.value
        },
        init() {
            this.initialised = false
            this.hasChanges = false
            this.updateSettings()
            this.updateUser()
            this.clearPasswordFields()
            setTimeout(() => {
                this.initialised = true
            }, 1000)
        },
        updateSettings() {
            const settings = eliberate(this.$store.state.settings)
            if (settings && !settings.general.appMode) {
                settings.general.appMode = 'pro'
            }
            this.settings = settings
        },
        updateUser() {
            this.user = {
                id: this.$auth.user.id,
                email: this.$auth.user.name,
                firstName: this.$auth.user.first_name,
                lastName: this.$auth.user.last_name,
            }
        },
        clearPasswordFields() {
            this.password = {
                password: '',
                passwordConfirm: '',
                oldPassword: '',
            }
        },
        async close() {
            if (this.hasChanges) {
                const confirmed = await this.$confirm(
                    'You have unsaved changes. Are you sure you want to close the settings dialog?',
                )
                if (!confirmed) return
            }
            this.value = false
        },
        validate(type = '') {
            switch (type) {
                case 'general':
                case 'netzero':
                // case 'presets':
                // eslint-disable-next-line no-fallthrough
                case 'reporting':
                    // nothing to validate, components can't be invalid (selects/switches)
                    return true
                default:
                    this.$v[type].$touch()
                    return this.$v[type].$error === false
            }
        },
        async save() {
            const isImpersonatingOnProd = this.$api.auth.impersonator() // && this.$api.$config.envName === 'production' // TODO Enable
            const tabs = ['user', 'password', 'general', 'reporting', 'netzero'] //, 'presets']
            const type = tabs[this.activeTab]
            if (!this.validate(type)) {
                this.$toast.error('Please fill in all the required information.')
                return
            }
            this.loading = true
            switch (type) {
                case 'user':
                    if (!isImpersonatingOnProd) {
                        await this.$api.auth.updateUser(this.user)
                    }
                    await this.$auth.fetchUser()
                    this.updateUser()
                    break
                case 'password':
                    await this.changePassword()
                    if (this.errorMessages) {
                        this.loading = false
                        return
                    }
                    this.clearPasswordFields()
                    this.$v.$reset()
                    break
                case 'general':
                    await this.resetProFunctionalities()
                    await this.$store.dispatch('updateSettings', this.getSettingsByType(type))
                    this.updateSettings()
                    await Unit.api().get('parameters', { persistBy: 'create' })
                    break
                // case 'presets':
                //     await this.setActivePresets()
                //     break
                default:
                    await this.$store.dispatch('updateSettings', this.getSettingsByType(type))
                    this.updateSettings()
            }

            setTimeout(() => {
                if (isImpersonatingOnProd) {
                    this.$toast.show('Impersonators cannot update user settings, changes are only applied locally', { type: 'warning' })
                } else {
                    this.$toast.success(`Your ${type} settings have been saved!`)
                }
                this.hasChanges = false
                this.loading = false
            }, 300)
        },
        getSettingsByType(type = '') {
            const params = [type]
            if (type === 'general') {
                params.push('changelog')
            }
            if (type === 'reporting') {
                params.push('entities', 'general.weekStartDay')
            }
            return this.$_.pick(this.settings, params)
        },
        async resetProFunctionalities() {
            if (this.settings.general.appMode === 'lite') {
                if (this.$store.state.clamp.initialised !== null) {
                    await this.$store.dispatch('clamp/toggleAnalysisFeature', { key: 'loadFactor', active: false })
                    await this.$store.dispatch('clamp/toggleContractsHistory', false)
                    if (this.$store.state.clamp.isInspectorVisible) await this.$store.dispatch('clamp/toggleInspector', false)
                    if (this.$store.state.period.resolution === VALID_RESOLUTIONS.MINUTE) {
                        await this.$PeriodService.updatePeriodWithDebounce({ resolution: VALID_RESOLUTIONS._30_MINUTES }, p => this.$store.dispatch('period/update', p))
                    }
                }
            }
        },
        async onTabChange() {
            if (this.hasChanges) {
                const confirmed = await this.$confirm(
                    'You have unsaved changes. Are you sure you want to change tab?',
                )
                if (confirmed) {
                    this.updateSettings()
                    this.activeTab = this.tab
                    this.$nextTick(() => {
                        this.hasChanges = false
                    })
                } else {
                    this.$nextTick(() => {
                        this.tab = this.activeTab
                    })
                }
            } else {
                this.activeTab = this.tab
            }
        },
    },
}
