import { difference, intersection } from 'lodash'
import { ENTITY_NAMES, EUID_SEPARATOR, getEuids, MANUFACTURER_CODES, parseEUID } from '~/utils/entities'

export default {
    init({ commit, rootState }) {
        const showMpan = this.$_.get(rootState, 'settings.entities.showMpanNumbers', false)
        if (showMpan) {
            commit('SET_SHOW_MPAN', true)
        }
        const showFriendlyName = this.$_.get(rootState, 'settings.entities.showFriendlyNames', false)
        if (showFriendlyName) {
            commit('SET_SHOW_FRIENDLY_NAME', true)
        }
    },

    setActiveEntities({ commit, dispatch, state }, { type, active }) {
        const maybeType = type !== 'all' && type !== 'virtual' ? 'all' : type
        if (!Array.isArray(active)) active = [active]
        if ( // when no need to update
            active.length === 0 &&
            state.activeEntities[maybeType].length === 0
        ) return
        dispatch('period/checkMinResolution', { euids: active }, { root: true })
        commit('SET_ACTIVE_ENTITIES', { type: maybeType, active })
    },

    addActiveEntity({ commit }, { type, euid }) {
        commit('ADD_ACTIVE_ENTITY', { type, euid })
    },

    removeActiveEntity({ commit }, { type, euid }) {
        commit('REMOVE_ACTIVE_ENTITY', { type, euid })
    },

    toggleActiveEntity({ commit, state }, { type, euid }) {
        const index = state.activeEntities[type].indexOf(euid)
        if (index >= 0) {
            commit('REMOVE_ACTIVE_ENTITY', { type, euid })
        } else {
            commit('ADD_ACTIVE_ENTITY', { type, euid })
        }
    },

    setSelectedEntities({ commit, dispatch, rootGetters }, { type, selected, clickedNode }) {
        if (selected.length) {
            dispatch('period/checkMinResolution', { euids: selected }, { root: true })
        }

        function getDescendants(node, descendants = []) {
            const children = node.children
            descendants.push(...children)
            for (let i = 0; i < children.length; i++) {
                descendants = getDescendants(children[i], descendants)
            }
            return descendants
        }

        // deselect entities if a zero-data entity in their ancestry was just manually deselected
        const justDeselected = clickedNode && !selected.includes(clickedNode.euid) ? clickedNode : null
        if (justDeselected && !justDeselected.activatable) {
            const toRemove = getDescendants(justDeselected).map(({ euid }) => euid)
            selected = difference(selected, toRemove)
        }
        const entities = selected.map(euid => euid.split(EUID_SEPARATOR))
        for (const [entity, id] of entities) {
            if (entity === ENTITY_NAMES.Clamp) {
                // for clamps that don't have data, if none of the children is selected, automatically select all children
                const clamp = rootGetters['entities/clamps/find'](id)
                if (clamp.manufacturer === MANUFACTURER_CODES.DUMMY && !clamp.hasData) {
                    const childrenEuids = getEuids(clamp.children.map(({ entity, id, $id }) => ({ entity, id, $id })))
                    const filteredEntities = selected.filter(c => parseEUID(c).id !== Number(id))
                    if (!intersection(childrenEuids, filteredEntities).length) {
                        selected.push(...childrenEuids)
                    }
                }
            }
        }

        commit('SET_SELECTED_ENTITIES', { type, selected })
    },

    addSelectedEntity({ commit }, { type, euid }) {
        commit('ADD_SELECTED_ENTITY', { type, euid })
    },

    removeSelectedEntity({ commit }, { type, euid }) {
        commit('REMOVE_SELECTED_ENTITY', { type, euid })
    },

    toggleSelectedEntity({ commit, state }, { type, euid }) {
        const index = state.selectedEntities[type].indexOf(euid)
        if (index >= 0) {
            commit('REMOVE_SELECTED_ENTITY', { type, euid })
        } else {
            commit('ADD_SELECTED_ENTITY', { type, euid })
        }
    },

    /**
     * Before preset delete cb action
     * - we need to remove any of it's childre from active/selected entities
     *
     * @param {Number} presetId
     * @returns {Void}
     */
    beforePresetDelete({ state, commit }, presetId) {
        const type = 'virtual'

        // for active
        const activeEntities = state.activeEntities.virtual
        if (activeEntities.length) {
            const activeEuids = activeEntities.filter(euid => !euid.includes(`${presetId}-presets`))
            if (activeEuids.length !== activeEntities.length) {
                commit('SET_ACTIVE_ENTITIES', { type, active: activeEuids })
            }
        }

        // for selected
        const selectedEntities = state.selectedEntities.virtual
        if (selectedEntities.length) {
            const selectedEuids = selectedEntities.filter(euid => !euid.includes(`${presetId}-presets`))
            if (selectedEuids.length !== selectedEntities.length) {
                commit('SET_SELECTED_ENTITIES', { type, selected: selectedEuids })
            }
        }
    },

    toggleMpan({ commit, state, dispatch }, value) {
        if (![true, false].includes(value)) {
            value = !state.showMpan
        }
        commit('SET_SHOW_MPAN', value)
        // if we want to persist the setting, uncomment the following line
        dispatch('updateSettings', { entities: { showMpanNumbers: value } }, { root: true })
    },

    toggleFriendlyName({ commit, state, dispatch }, value) {
        if (![true, false].includes(value)) {
            value = !state.showFriendlyName
        }
        commit('SET_SHOW_FRIENDLY_NAME', value)
        // if we want to persist the setting, uncomment the following line
        dispatch('updateSettings', { entities: { showFriendlyNames: value } }, { root: true })
    },

    removeSelectedNoLongerInPreset({ commit, state, rootGetters }, preset) {
        const flatten = array => array.flatMap(p => [
            p,
            ...flatten(p.children || []),
        ])
        // eslint-disable-next-line eqeqeq
        const presetItem = rootGetters['clamp/virtualTreeViewItems'][0].children.find(p => p.$id == preset.id)
        const presetEntities = flatten(presetItem.children)

        const selected = state.selectedEntities.virtual.filter(p => !p.includes(presetItem.euid) ||
            presetEntities.map(o => o.euid).includes(p))

        commit('SET_SELECTED_ENTITIES', { type: 'virtual', selected })
    },
}
