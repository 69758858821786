import phone from 'phone'

export function validateEmail(email) {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}

export function validateMobilePhoneNumber(phoneNumber) {
    return phone(phoneNumber)
}

/**
 * @param password
 * @returns {boolean}
 */
export function validatePassword(password) {
    const re = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#$£%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]).{12,50}$/gm
    return re.test(password)
}
