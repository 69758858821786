
import { knownCommodities, LAYOUT_OPTIONS_BY_COMMODITY } from '~/utils/commodities'

export default {
    data() {
        return {
            downloadButtonDisabled: false,
        }
    },

    computed: {
        items() {
            const unseenNotifications = this.$store.state.entities.notifications.inbox?.counts?.unseenNotifications

            const items = knownCommodities.map(c => {
                const { menu, route, scope } = LAYOUT_OPTIONS_BY_COMMODITY(c)
                if (!menu.active) return null
                return {
                    icon: menu.icon,
                    title: this.$t(`pages.${scope?.long ?? c}.title`),
                    to: this.localePath(`/${route?.path ?? c}`),
                }
            }).filter(f => f)
            if (this.$config.features.dashboardEnabled) {
                items.unshift({
                    icon: '$cvdashboard',
                    title: this.$t('pages.dashboard.title'),
                    to: this.localePath('/'),
                })
            }
            if (this.$config.features.jobsEngineEnabled) {
                items.push({
                    icon: '$arrowProgress', // '$abacus',
                    title: this.$t('pages.jobs.title'),
                    to: this.localePath('/jobs'),
                })
            }
            if (this.$config.features.leagueTable) {
                items.push({
                    icon: '$cvgrid',
                    title: 'League table',
                    to: this.localePath('/league-table'),
                })
            }
            if (this.$config.features.alertsEnabled) {
                items.push({
                    icon: '$cvalarm',
                    title: this.$t('pages.alerts.title'),
                    to: this.localePath('/alerts'),
                    notifications: unseenNotifications,
                })
            }
            if (this.$config.features.carbonEnabled) {
                items.push({
                    icon: '$cvc02',
                    title: 'Carbon',
                    to: '/carbon',
                    // badge: 'beta',
                })
            }
            if (this.$config.features.eventsEnabled) {
                items.push({
                    icon: '$cvcalendar',
                    title: 'Events',
                    to: '/events',
                })
            }
            if (this.$config.features.flowDesignerEnabled) {
                items.push({
                    icon: '$cvflows',
                    title: 'Flows',
                    to: '/flows',
                })
            }
            if (this.$config.features.netZeroPage) {
                items.push({
                    icon: '$cvnetzero',
                    title: 'Net zero',
                    to: '/net-zero',
                })
            }
            if (this.$config.features.entitiesPage) {
                items.push({
                    icon: '$gears',
                    title: 'Entities',
                    to: '/entities',
                })
            }
            return items
        },
        canDownload() {
            const customer = this.$store.getters['entities/customers/all']()[0]
            return (
                customer &&
                customer.settings &&
                customer.settings.downloadsEnabled &&
                customer.settings.downloadsEnabled.length
            )
        },
        activeRoute() {
            return this.$route.path
        },
    },

    methods: {
        async downloadJSON() {
            this.downloadButtonDisabled = true
            const result = await this.$store.dispatch('clamp/downloadJSON')
            if (result !== true) {
                this.$toast.show(result)
            }
            this.downloadButtonDisabled = false
        },
    },
}
