/**
 * Vuex ORM plugin
 */
export default {
    /**
     * Install plugin
     *
     * @param {Object} components [contains Vuex ORM objects such as Model and Query.]
     * @param {Object} options [plugin options]
     * */
    install(components, options) {
        // Add global (static) method or property.
        components.Model.state = function() {
            return {
                fetched: false,
                fetchingPromise: null,
                fetchedByCommodity: {},
            }
        }

        components.Model.fetchUri = function() {
            const baseURL = this.apiConfig.baseURL || ''
            return `${baseURL}/${this.apiConfig?.url || this.entity}`
        }

        components.Model.getParams = function() {
            return this.apiConfig?.params || {}
        }

        components.Model.fetch = async function({ forced, params } = {}) {
            const entity = this.entity
            const store = this.store()
            const state = store.state.entities[entity]
            const mergeParams = { ...this.getParams(), ...params }
            const isCommodityHasInParams = mergeParams?.commodity?.length
            // We check if a commodity has already been fetched for the entity
            if (isCommodityHasInParams) {
                // Filter out commodities that have already been fetched
                const commoditiesToFetch = mergeParams.commodity.filter(c => !state.fetchedByCommodity[c])
                if (!commoditiesToFetch.length && state.fetched && !forced) return

                // Update the state.fetched to false if there are still commodities to fetch
                if (commoditiesToFetch.length) {
                    await this.commit(state => {
                        state.fetched = false
                    })
                    mergeParams.commodity = commoditiesToFetch
                }
            }

            if (state.fetched && !forced) return
            if (!forced) {
                store.commit('global/SET_LOADER_VISIBLE', true)
            }

            let promise = state.fetchingPromise
            if (!promise) {
                promise = this.api().get(this.fetchUri(), { params: mergeParams }).then(r => {
                    this.commit(state => {
                        if (!forced) {
                            store.commit('global/MARK_ENTITY_FETCHED', entity)
                            store.state.global.loader.indicator += 1
                        }
                        state.fetched = true
                        state.fetchingPromise = null
                    })
                }).catch(e => {
                    console.log('e', e)
                })

                this.commit(state => {
                    state.fetchingPromise = promise
                    if (isCommodityHasInParams) {
                        mergeParams.commodity.forEach(c => {
                            state.fetchedByCommodity[c] = true
                        })
                    }
                })
            }
            return promise
        }

        components.Model.dupeEntities = async function() {
            function random(a) {
                return a[Math.floor((Math.random() * a.length))]
            }
            const siteIds = []
            const meterIds = []
            const sites = this.store().$db().model('sites').all().map(e => {
                const newId = Math.round(Math.random() * 1e5) + 1e5
                siteIds.push(newId)
                return {
                    ...e.$toJson(),
                    [this.store().$db().model('sites').primaryKey]: newId,
                }
            })
            const meters = this.store().$db().model('meters').all().map(e => {
                const newId = Math.round(Math.random() * 1e5) + 1e5
                meterIds.push(newId)
                return {
                    ...e.$toJson(),
                    siteId: random(siteIds),
                    [this.store().$db().model('meters').primaryKey]: newId,
                }
            })
            const clamps = this.store().$db().model('clamps').all().map(e => {
                const newId = Math.round(Math.random() * 1e5) + 1e5
                // meterIds.push(newId)
                return {
                    ...e.$toJson(),
                    meterId: random(meterIds),
                    [this.store().$db().model('clamps').primaryKey]: newId,
                }
            })

            await this.store().$db().model('sites').insert({
                data: sites,
            })
            await this.store().$db().model('meters').insert({
                data: meters,
            })
            await this.store().$db().model('clamps').insert({
                data: clamps,
            })
            console.log('dupeEndtite', this)
            // await this.addTest(this.all())
            this.store().dispatch('clamp/setTreeItems', { forced: true })
        }

        // Add an instance method or property.
        // components.Query.prototype.instanceMethod = function () {}
    },
}
