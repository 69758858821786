/** * ORM ***/
import download from 'downloadjs'
import { concat, xor } from 'lodash'
import { getScopeByCommodity } from '~/utils/commodities'
import { buildTree, transformEntitiesByCommodity } from '~/utils/entities'
/** * Utils ***/
import { eliberate, FROM_ROOT } from '~/utils/state'

export default {
    /**
     * Initialise the store
     * Ideally called from the gas page
     * @param  {Function} options.commit
     * @param  {Function} options.dispatch
     * @return {Void}
     */
    async init({ commit, dispatch, getters, state, rootGetters }, payload = {}) {
        const { options } = payload
        const commodity = state.commodity
        // start loading
        if (!state.loading) {
            commit('SET_LOADING', true)
        }

        if (!getters.supported) {
            console.warn('Feature is not supported atm.')
            commit('SET_LOADING', false)
            commit('SET_INITIALISED', true)
            return
        }
        // init cache
        await this.$cache.init()
        // setup clamps
        await Promise.all([
            dispatch('entities/clamps/setup', { commodity }, FROM_ROOT),
            dispatch('entities/clamps/setupVirtual', { commodity }, FROM_ROOT),
        ])

        const { start: firstInTime, end: lastInTime } = rootGetters.rankingCommodity(commodity)
        const initPeriod = { min: firstInTime, max: lastInTime }
        if (firstInTime || lastInTime) {
            this.$PeriodService.updatePeriodWithDebounce(initPeriod, p => dispatch('period/init', p, FROM_ROOT))
        }

        if (rootGetters['entities/clamps/isThereAtLeastOneMeterWithNoMainIncomer']) {
            commit('SET_AUTO_Y_AXIS', true)
            commit('SET_DEFAULT_AUTO_Y_AXIS', true)
        }

        dispatch('setTreeItems', { commodity })
        dispatch('setVirtualTreeItems', { commodity })
        dispatch('initTreeviews', { commodity })
        dispatch('setComparison', state.comparison ? null : state.comparison)

        if (rootGetters.isLite) {
            commit('SET_SELECTED_PARAMETERS_PRESET', `default-${commodity}`)
        } else {
            commit('SET_SELECTED_PARAMETERS_PRESET', rootGetters['entities/parameters-presets/default'](commodity))
        }

        if (options.selectedParameterKeys && options.selectedParameterKeys.length) {
            commit('SET_SELECTED_PARAMETER_KEYS', payload?.options.selectedParameterKeys)
        }

        // init unit
        dispatch('initUnit')

        // stop loading
        commit('SET_LOADING', false)

        // init
        commit('SET_INITIALISED', true)
    },

    initUnit({ getters, commit }) {
        const unitIdFromHash = this.$_.get(this.$hashState, 'params.unit', null)
        const units = getters.preset.parameters
        let unit = units.find(u => (u.id === unitIdFromHash || u.param === unitIdFromHash) && getters.selectedClamps.every(c => c.supportsUnit(u)))
        if (!unit) {
            unit = units.find(u => getters.selectedClamps.every(c => c.supportsUnit(u)))
        }
        if (unit) commit('SET_SELECTED_UNIT', unit.key)
    },

    /**
     * Set tree items
     *
     * @param {Function} commit
     * @param {Object} rootGetters
     *
     * @returns {Void}
     */
    setTreeItems({ commit, rootGetters, state }, { forced = false, commodity } = {}) {
        if (!forced && state.treeItems[commodity].all.length > 0) return
        const allItems = rootGetters['entities/customers/query']()
            .with('sites', siteQuery =>
                siteQuery.whereHas('meters', meterQuery =>
                    meterQuery.where('commodity', commodity),
                )
                    .with('meters', meterQuery =>
                        meterQuery.where('commodity', commodity)
                            .with('clamps', clampQuery =>
                                clampQuery.where('parentId', null).where('commodity', commodity),
                            ),
                    ),
            ).get()
        commit('SET_TREE_ITEMS', { [commodity]: { all: Object.freeze(buildTree({ items: allItems, store: this })) } })
    },

    /**
     * Set virtual tree items
     *
     * @param {Function} commit
     * @param {Object} rootGetters
     *
     * @returns {Void}
     */
    setVirtualTreeItems({ commit, rootGetters, state }, { forced = false, commodity } = {}) {
        if (!forced && state.treeItems[commodity].virtual.length > 0) return
        const virtualItems = rootGetters['entities/customers/query']()
            .with('presets', q => q.where('commodity', commodity))
            .get()
        commit('SET_TREE_ITEMS', { [commodity]: { virtual: Object.freeze(buildTree({ items: virtualItems, store: this })) } })
    },

    /**
     *  Init the electricity treeviews
     *  check if selected entities in hash state or fallback to default
     *
     * @param { dispatch, getters } context
     */
    initTreeviews({ dispatch, getters, rootState, rootGetters }, { commodity } = {}) {
        const selectedSiteIds = rootState.selectedSiteIds
        const suffix = `${commodity}Treeview`
        const hashState = this.$_.get(this.$hashState, `params.${suffix}`)
        const treeview = this.$_.get((hashState ? transformEntitiesByCommodity({ [suffix]: hashState }) : null) || rootGetters.userState, suffix)
        const userSelectedEntities = treeview ? rootGetters['entities-manager/validatedTreeview'](treeview) : null
        let tabIndex = 0
        if (userSelectedEntities && userSelectedEntities.type) {
            tabIndex = ['all', 'virtual'].indexOf(userSelectedEntities.type)
            dispatch('clamp/setTabIndex', tabIndex, FROM_ROOT)
        }
        if (userSelectedEntities?.selected?.length || userSelectedEntities?.active?.length) {
            dispatch('entities-manager/setSelectedEntities', userSelectedEntities, FROM_ROOT)
            dispatch('entities-manager/setActiveEntities', userSelectedEntities, FROM_ROOT)
        } else {
            const euids = selectedSiteIds.length > 0 ? getters.sitesTreeviewItems(selectedSiteIds) : getters.initialTreeViewItems()
            dispatch('entities-manager/setSelectedEntities', { type: 'all', selected: [...euids] }, FROM_ROOT)
            dispatch('entities-manager/setActiveEntities', { type: 'all', active: [] }, FROM_ROOT)
        }

        if (tabIndex === 0) {
            const virtualEuids = getters.initialTreeViewItems(true)
            dispatch('entities-manager/setSelectedEntities', { type: 'virtual', selected: [...virtualEuids] }, FROM_ROOT)
            dispatch('entities-manager/setActiveEntities', { type: 'virtual', active: [] }, FROM_ROOT)
        }
    },

    /**
     * Set tab index
     * @param {Function} options.commit
     * @param {Number} value
     */
    setTabIndex({ commit }, value) {
        value = String(value)
        if (!['0', '1'].includes(value)) {
            return // invalid index
        }
        commit('SET_TAB_INDEX', value)
    },

    toggleAnalysisFeature({ commit, dispatch, rootState }, payload) {
        commit('TOGGLE_ANALYSIS_FEATURE', payload)

        // when activating Max Demands
        if (payload.key === 'maxDemands' && payload.active) {
            this.$PeriodService.updatePeriodWithDebounce({
                start: rootState.period.min,
                end: rootState.period.max,
            }, p => dispatch('period/update', p, FROM_ROOT))
        }
    },

    setSelectedUnit({ commit }, unit) {
        commit('SET_SELECTED_UNIT', unit)
    },

    addSnapshot({ commit }, v) {
        commit('ADD_SNAPSHOT', { ...v, id: this.$_.uniqueId('snapshot-') })
    },

    selectSnapshot({ commit, state }, v) {
        commit(
            'SET_SNAPSHOTS',
            state.snapshots.map(snapshot => {
                return {
                    ...snapshot,
                    selected: snapshot.id === v.id,
                }
            }),
        )
    },

    setSelectedPoint({ commit }, v) {
        commit('SET_SELECTED_POINT', v)
    },

    toggleExtremePoint({ dispatch, state }, extreme = 'max') {
        let point
        if (state.selectedPoint && state.selectedPoint.extreme === extreme) {
            point = null
        } else {
            point = {
                extreme,
                uid: this.$_.uniqueId('point_'),
            }
        }
        dispatch('setSelectedPoint', point)
    },

    toggleChartAutoYAxis({ commit, state }) {
        commit('SET_AUTO_Y_AXIS', !state.autoYAxis)
    },

    setChartAutoYAxis({ commit }, v) {
        commit('SET_AUTO_Y_AXIS', v)
    },

    setTriadsReadings({ commit }, v) {
        commit('SET_TRIADS_READINGS', v)
    },

    setLoadFactorData({ commit }, v) {
        commit('SET_LOAD_FACTOR_DATA', v)
    },

    setConsolidation({ commit }, value) {
        commit('SET_CONSOLIDATION', value)
    },

    toggleConsolidation({ commit, state }) {
        commit('SET_CONSOLIDATION', !state.consolidation)
    },

    setComparison({ commit }, value) {
        commit('SET_COMPARISON', value)
    },

    toggleComparison({ dispatch, state, rootGetters }) {
        const comparison = state.comparison ? null : this.$_.clone(rootGetters['period/comparison'])
        dispatch('setComparison', comparison)
    },

    toggleRag({ commit, state }, value = null) {
        commit('SET_RAG', this.$_.isBoolean(value) ? value : !state.ragEnabled)
    },

    async downloadJSON({ state, rootState, rootGetters }) {
        const { period } = rootState
        const customer = rootGetters['entities/customers/all']()[0]
        const { unit, resolution } = this.$_.get(customer, 'settings.downloadsEnabled.0', {})
        /**
         * Temporarily only support one unit
         */
        if (!(unit && resolution)) {
            return "You don't have the access to this feature"
        }
        const outputResolution = resolution / 60
        if (outputResolution < period.resolution / 60) {
            return `Found misconfiguration in the output resolution (${outputResolution}, ${period.resolution / 60})`
        }
        const allClamps = rootGetters['entities/clamps/haveData'](state.commodity)
        const data = await Promise.allSettled(allClamps.map(c => c.getUnitData(unit, state.commodity)))
        const output = []
        allClamps.forEach((clamp, k) => {
            if (data[k] && data[k].status === 'fulfilled') {
                const originalClampData = eliberate(data[k].value.data)
                const startTime = this.$moment(data[k].value.startTime)
                // i (Tony :D) want to round it up to the closest hour
                const howManyPointsToSkip =
                    startTime.minutes() !== 0
                        ? 60 / (period.resolution / 60) - (60 / (period.resolution / 60)) * (startTime.minutes() / 60)
                        : 0
                startTime.add(howManyPointsToSkip * period.resolution, 'seconds')
                let clampData = originalClampData.splice(howManyPointsToSkip)
                clampData = this.$_.chunk(clampData, outputResolution / (period.resolution / 60)).map((e, k) => {
                    return {
                        dt: this.$moment(startTime)
                            .add(k * outputResolution, 'minutes')
                            .format(),
                        kwh: Number(this.$_.sum(e)).toFixed(4),
                    }
                })
                output.push({
                    site: clamp.relationships.site.name,
                    pipeName: clamp.pipeName,
                    data: clampData,
                })
            }
        })
        download(
            JSON.stringify(output, null, 4),
            `${this.$moment().format('YYYY_MM_DD_HH_mm_ss')}_kwh_output.json`,
            'text/plain',
        )
        return true
    },

    setRenderedTraces({ commit }, value) {
        commit('SET_RENDERED_TRACES', value)
    },

    setAnnotationsMode({ commit }, value) {
        commit('SET_ANNOTATIONS_MODE', value)
    },

    reset({ commit, dispatch }, { hard = false } = {}) {
        dispatch('resetChart')
        // @Todo Reset Part we need to handle it
        dispatch('period/reset', { resolution: 1800 }, FROM_ROOT)
        this.$eventBus.$emit('hard-reset')

        if (hard) {
            commit('RESET')
        }
    },

    resetChart({ dispatch }) {
        dispatch('setSelectedPoint', null)
        dispatch('setComparison', null)
        dispatch('toggleRag', false)
        dispatch('setAnnotationsMode', false)
        dispatch('setChartAutoYAxis', true)
        dispatch('toggleContractsHistory', false)
        dispatch('resetAnalysisFeatures')
    },

    destroy({ commit }) {
        commit('SET_INITIALISED', null)
        commit('SET_LOADING', false)
        commit('SET_COMMODITY', null)
        commit('SET_SCOPE', null)
        commit('RESET')
    },

    async toggleContractsHistory({ commit, rootGetters, state }, enable = true) {
        const supplierRatesKeys = {
            SUPPL_DAY: 'day_rate',
            SUPPL_NIGHT: 'night_rate',
            SUPPL_OFFPEAK_WEEKEND: 'offpeak_weekend_rate',
            SUPPL_SETTLEMENT_CHARGE: 'settlement_charge',
            SUPPL_STANDING_CHARGE: 'standing_charge',
        }
        const ratesLabels = {
            SUPPL_DAY: 'label.day_rate',
            SUPPL_NIGHT: 'label.night_rate',
            SUPPL_OFFPEAK_WEEKEND: 'label.offpeak_weekend',
            SUPPL_STANDING_CHARGE: 'label.standing_charge',
            SUPPL_SETTLEMENT_CHARGE: 'label.settlement_charge',
            DUOS_GREEN: 'color.green',
            DUOS_AMBER: 'color.amber',
            DUOS_RED: 'color.red',
            DUOS_CAPACITY: 'label.capacity',
        }
        let contracts = []
        if (!state.selectedMeterContracts.length && enable) {
            const meter = rootGetters['entities-manager/selectedClampsMeters']()[0]
            let duosRates = {}
            const isPassThrough = meter.contracts[0]?.passed_through_elements && meter.contracts[0].passed_through_elements.length
            if (isPassThrough && state.commodity === 'power') {
                const parsedTop = /^(\d{2})(\d{3})(\w{3})$/.exec(meter.mpanTop)
                const parsedBottom = /^(\d{2})(\d{8})(\d{3})$/.exec(meter.mpanBottom)
                const params = {
                    gspGroup: meter.gspGroup,
                    from: meter.contracts[0].start_date,
                    to: meter.contracts[meter.contracts.length - 1].end_date,
                    forecast: false,
                    distributorId: parsedBottom[1], // validate ?
                    lineLossFactorClass: parsedTop[3],
                    profileClass: parsedTop[1],
                }
                duosRates = await this.$api.electricity.getRates(params)
            }
            contracts = meter.contracts.map(contract => {
                let rates = []
                if (isPassThrough) {
                    rates = contract.passed_through_elements.filter(p =>
                        ['DUOS_GREEN', 'DUOS_AMBER', 'DUOS_RED', 'DUOS_CAPACITY'].includes(p)).map(p => {
                        return duosRates?.rates?.[p]?.filter(r =>
                            this.$moment(contract.start_date).isBetween(r.from, r.to) ||
                                this.$moment(contract.end_date).isBetween(r.from, r.to),
                        ).map((duos, index, length) => ({
                            name: ratesLabels[duos.name],
                            value: duos.rate,
                            to: index < length - 1 ? this.$moment(duos.to).format('DD-MM-YYYY') : undefined,
                        }))
                    })
                }
                if (meter.mainIncomer?.isNonHalfHourly()) {
                    rates = contract.standing_charges.map(r => ({ name: ratesLabels[r.key], value: r.rate, ...r })).concat()
                } else {
                    rates = concat([...new Set(concat(contract.supplier_elements, 'SUPPL_STANDING_CHARGE'))].map(r => {
                        if (!ratesLabels[r]) return null
                        return {
                            name: ratesLabels[r],
                            value: contract[supplierRatesKeys[r]],
                        }
                    }), rates).filter(f => f)
                }
                return {
                    id: contract.id,
                    start_date: contract.start_date,
                    end_date: contract.end_date,
                    supplier: contract.supplier_name ?? contract.supplier_mpid,
                    rates,
                }
            })
        }
        commit('SET_METER_CONTRACTS', contracts)
    },

    selectParametersPreset({ commit, getters }, v) {
        commit('SET_SELECTED_PARAMETERS_PRESET', v)
        const key = getters.availableParameter
        commit('SET_SELECTED_PARAMETER_KEYS', key ? [key] : [])
    },
    updateNode({ commit, state }, node) {
        commit('UPDATE_NODE', node)
    },

    toggleSelectedParameterKeys({ state, commit }, v) {
        commit('SET_SELECTED_UNIT', null)
        commit('SET_SELECTED_PARAMETER_KEYS', xor(state.selectedParameterKeys, [v]))
    },

    toggleMultipleSelectedParameterKeys({ state, commit }, values) {
        commit('SET_SELECTED_UNIT', null)
        commit('SET_SELECTED_PARAMETER_KEYS', xor(state.selectedParameterKeys, values))
    },

    setSelectedParameterKeys({ state, commit }, v) {
        commit('SET_SELECTED_PARAMETER_KEYS', [v])
    },

    resetSelectedParameterKeys({ getters, commit }) {
        commit('SET_SELECTED_UNIT', null)
        commit('SET_SELECTED_PARAMETER_KEYS', [...(getters.preset?.keysOnly?.length ? [getters.preset.keysOnly[0]] : [])])
    },

    setTracesVisibility({ state, commit, getters }, traces) {
        const v = {
            ...state.tracesVisibility,
            ...traces,
        }
        commit('SET_TRACES_VISIBILITY', v)
    },

    toggleInspector({ state, commit }, value) {
        commit('SET_IS_INSPECTOR_VISIBLE', value ?? !state.isInspectorVisible)
    },

    checkActiveClamps({ state, commit, getters, rootGetters }, tabId) {
        if (rootGetters['entities-manager/selectedClamps'](tabId, true).length > 1 && state.selectedParameterKeys?.length > 1) {
            this.$toast.info('You can\'t select more than one clamp when two or more parameters are selected')
            return false
        }
        return true
    },

    setDrillDown({ commit }, value) {
        commit('SET_DRILL_DOWN', value)
    },

    closeDrilldown({ commit }) {
        commit('SET_DRILL_DOWN', {
            unit: '',
            entity: '',
            entityIds: [],
            entityNames: [],
            previousPeriod: {},
            period: {},
        })
    },

    resetAnalysisFeatures({ commit }) {
        commit('RESET_ANALYSIS_FEATURES')
    },

    setCommodityAndScope({ commit }, commodity) {
        commit('SET_COMMODITY', commodity)
        commit('SET_SCOPE', getScopeByCommodity(commodity).long)
    },
}
