import { Model } from '@vuex-orm/core'
import { pickBy, size, cloneDeep, forOwn } from 'lodash'

/** ORM **/
import Clamp from '~/orm/models/Clamp'
import Unit from '~/orm/models/Unit'
import Preset from '~/orm/models/Preset'

export default class Alert extends Model {
    static entity = 'alerts'

    static primaryKey = 'id'

    static ACCESS_TYPES = {
        PUBLIC: 'public',
        PRIVATE: 'private',
    }

    static apiConfig = {
        actions: {
            toggleStatus(model, enabled) {
                this.put(`${Alert.fetchUri()}/${model.id}`, { enabled })
            },
            async save(model) {
                const alert = Alert.prepareForSaving(cloneDeep(model))
                const param = alert.conditions[0]?.field
                const unit = Unit.findByParam(param)
                if (param === 'U_GENE') {
                    alert.conditions[0].reference.value /= unit.multiplier
                } else {
                    alert.conditions[0].reference.value *= unit.multiplier
                }
                let r
                if (alert.id) {
                    r = await this.put(`${Alert.fetchUri()}/${alert.id}`, alert)
                } else {
                    r = await this.post(Alert.fetchUri(), alert)
                }
                await this.get(Alert.fetchUri())
                return r
            },
        },
    }

    static fields() {
        return {
            id: this.attr(null),
            name: this.attr(null),
            description: this.attr(null),
            combinator: this.attr('and'),
            conditions: this.attr([]),
            recipients: this.attr([]),
            schedule: this.attr({}),
            enabled: this.boolean(true),
            scope: this.attr(null),
            isEditable: this.boolean(true),
            access: this.attr(this.ACCESS_TYPES.PRIVATE),
            userName: this.attr(null),
            timezone: this.attr(null),
            resolution: this.attr(null),
        }
    }

    get entities() {
        let entities
        if (this.conditions[0].base.entities[0].type === 'virtual') {
            entities = Preset.query()
                .whereIdIn(this.conditions[0].base.entities.map(e => parseInt(`${e.id}`.replace('V', ''))))
                .get()
        } else {
            entities = Clamp.query().whereIdIn(this.conditions[0].base.entities.map(e => e.id)).get()
        }
        return entities
    }

    get unit() {
        let param = this.conditions[0]?.field
        if (param === 'U_CO2') {
            param = 'U_CO2E'
        }
        return Unit.findByParam(param)
    }

    get notifications() {
        const messages = this.constructor.store().state.entities.notifications.inbox?.messages
        if (messages) {
            const m = messages.find(e => e.alert.id === this.id)
            if (m) {
                return m.counts.unseenNotifications
            }
        }
        return 0
    }

    static prepareForSaving(model) {
        const activeScheduleDays = pickBy({ ...model.schedule }, e => e.find(({ active }) => active))
        forOwn(activeScheduleDays, (e, k) => {
            e.forEach((v, j) => {
                if (!v.active) {
                    // delete activeScheduleDays[k][j]
                    activeScheduleDays[k].splice(j, 1)
                } else if (v.criteria === 'outside') {
                    const copy = { start: v.start, end: v.end }
                    activeScheduleDays[k] = [
                        {
                            start: '00:00',
                            end: copy.start,
                            active: true,
                            criteria: 'outside',
                        },
                        {
                            start: copy.end,
                            end: '23:59',
                            active: true,
                            criteria: 'outside',
                        },
                    ]
                }
            })
        })
        // const activeScheduleDays = map(model.schedule, s => s.filter(({ active }) => active))
        // console.log('activeScheduleDays', activeScheduleDays)
        return {
            ...model,
            /** the following block is temporary **/
            conditions: model.conditions.map(condition => {
                return {
                    ...condition,
                    base: {
                        ...condition.base,
                        entities: condition.base.entities.map(entity => {
                            return {
                                ...entity,
                                id: parseInt(`${entity.id}`.replace('V', '')),
                                // type: 'clamp'
                            }
                        }),
                    },
                    reference: {
                        ...condition.reference,
                        entities:
                            condition.reference.type === 'fixed'
                                ? null
                                : condition.base.entities.map(entity => {
                                    return {
                                        ...entity,
                                        id: parseInt(`${entity.id}`.replace('V', '')),
                                        // type: 'clamp'
                                    }
                                }),
                        window: condition.reference.window * 86400,
                        value: condition.reference.type === 'fixed' ? condition.reference.value : null,
                        scale:
                            condition.reference.type === 'fixed'
                                ? 1
                                : (() => {
                                    const value = condition.reference.value
                                    if (condition.comparator === 'above') {
                                        return value / 100 + 1
                                    } else {
                                        return 1 - value / 100
                                    }
                                })(),
                    },
                }
            }),
            schedule: activeScheduleDays,
        }
    }

    static beforeUpdate(model) {
        let param = model.conditions[0]?.field
        if (param === 'U_CO2') {
            param = 'U_CO2E'
        }
        const unit = Unit.findByParam(param)
        if (param === 'U_GENE') {
            model.conditions[0].reference.value *= unit.multiplier
        } else {
            model.conditions[0].reference.value /= unit.multiplier
        }
        if (model.conditions[0].reference.window) {
            model.conditions[0].reference.window /= 86.4e3
            model.conditions[0].reference.value = Math.abs(
                Number(((model.conditions[0].reference.scale - 1) * 100).toPrecision(2)),
            )
        }

        model.conditions[0].base.entities = model.conditions[0].base.entities.map(entity => {
            return {
                ...entity,
                id: entity.type === 'virtual' ? `V${entity.id}` : entity.id,
            }
        })
    }

    static beforeCreate(model) {
        let param = model.conditions[0]?.field
        if (param === 'U_CO2') {
            param = 'U_CO2E'
        }
        const unit = Unit.findByParam(param)
        if (param === 'U_GENE') {
            model.conditions[0].reference.value *= unit.multiplier
        } else {
            model.conditions[0].reference.value /= unit.multiplier
        }
        if (model.conditions[0].reference.window) {
            model.conditions[0].reference.window /= 86.4e3
            model.conditions[0].reference.value = Math.abs(
                Number(((model.conditions[0].reference.scale - 1) * 100).toPrecision(2)),
            )
        }
        model.conditions[0].base.entities = model.conditions[0].base.entities.map(entity => {
            return {
                ...entity,
                id: entity.type === 'virtual' ? `V${entity.id}` : entity.id,
                // type: 'clamp'
            }
        })

        if (unit) {
            model.scope = unit.scope
        } else {
            model.scope = 'electricity'
        }
    }

    static nextId() {
        const lastAlert = Alert.query().last()
        return ((lastAlert && lastAlert.id) || 0) + 1
    }

    static generateInitialSchedule(schedule = {}) {
        const r = {}
        for (let i = 1; i <= 7; i++) {
            r[i] =
                schedule && schedule[i]
                    ? Array.from({ length: 1 }, (_, k) => {
                        if (schedule[i][k]) {
                            if (k === 0 && schedule[i][k].criteria === 'outside') {
                                return {
                                    active: true,
                                    criteria: 'outside',
                                    start: schedule[i][0].end,
                                    end: schedule[i][1].start,
                                }
                            } else {
                                return {
                                    ...schedule[i][k],
                                    active: true,
                                    // criteria: 'between'
                                }
                            }
                        } else {
                            return {
                                start: '00:00', end: '23:59', active: false, criteria: 'between',
                            }
                        }
                    })
                    : [{ start: '00:00', end: '23:59', active: size(schedule) <= 0, criteria: 'between' }]
        }
        return r
    }

    static init(options = {}) {
        const draft = Alert.hydrate(options)
        draft.conditions = [
            {
                field: null,
                comparator: null,
                base: {
                    type: 'latest',
                    entities: [],
                },
                reference: {
                    type: 'fixed',
                    value: null,
                    window: 30, // 365 days default
                },
            },
        ]
        draft.schedule = Alert.generateInitialSchedule()
        draft.by = null
        return draft
    }
}
