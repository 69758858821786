import { omit } from 'lodash'
import moment from '~/composables/useMoment'
import { fileDownload, filesDownload } from '~/utils/tools'

export default class ElectricityApiService {
    constructor({ $axios, $config }) {
        this.$axios = $axios
        this.$config = $config
    }

    fetchRagBands(params = {}) {
        // params.gspGroup = '_F'
        // return this.$axios.$get('https://rates.clearvueapi.dev//v1/rag', { params })
        return Promise.resolve({
            gspGroup: '_F',
            red: [{ start: '16:00', end: '19:29', is_weekend: false }],
            amber: [
                { start: '08:00', end: '15:59', is_weekend: false },
                { start: '19:30', end: '21:59', is_weekend: false },
            ],
            green: [
                { start: '00:00', end: '07:59', is_weekend: false },
                { start: '22:00', end: '23:59', is_weekend: false },
                { start: '00:00', end: '23:59', is_weekend: true },
            ],
        })
    }

    getAverageLines(params = {}, url = '/smart-analysis') {
        params.type = ['avg-lines']
        return this.$axios.$get(url, { params })
    }

    getPeakTimes(params = {}, url = `${process.env.urls.api}siteAnalytics/getSiteRAG`) {
        return this.$axios.$get(url, { params })
    }

    getTriads(params = {}, url = `${process.env.urls.api}getTriadsForPeriod`) {
        params.customerId = 1
        return this.$axios.$get(url, { params })
    }

    getMockData() {
        return this.$axios.$get('mocks/power-factor.json', { baseURL: '/' })
    }

    /**
     * Get the urls for the PDF files to download
     * @param  {Object} params
     * @return {Array}
     */
    async downloadInspectorData(params = {}) {
        let uri
        let method = '$post'
        const fileType = params.fileType
        const config = {
            params,
        }
        if (fileType === 'xlsx') {
            config.params = {
                ...params,
                ids: params.clampIds,
                daterange: [params.start, params.end],
            }
            config.params = omit(config.params, ['clampIds', 'start', 'end'])
            uri = '/v3/reports/xlsx'
            method = '$get'
            config.responseType = 'blob'
        } else if (params.comparison) {
            method = '$get'
            uri = '/v2/reports/comparison'
            config.params = {
                use_friendly_names: params.use_friendly_names,
                useFriendlyNames: params.useFriendlyNames,
                circuit: params.clampIds[0],
                resolution: params.resolution,
                base: [params.start, params.end],
                comparison: [params.comparison.start, params.comparison.end],
                timezone: params.timezone,
            }
        } else if (params.unit === 'cost') {
            uri = '/v2/reports/cost-report'
        } else {
            uri = `/v2/reports/inspector/${fileType}`
        }

        let success = false
        const links = []
        try {
            const res = await this.$axios[method](`${this.$config.axios.reportsServiceURL}${uri}`, config)
            if (Array.isArray(res)) {
                success = true
                res.filter(v => v).forEach(({ success, data }) => {
                    success && links.push(data[`${fileType}_src_url`])
                })
            } else if (res.success) {
                success = true
                links.push(res.data[`${fileType}_src_url`])
            } else {
                // this should be binary data of the file
                success = true
                fileDownload(
                    res,
                    `${moment().format('YYYYMMDD_HHmmss')}_readings_report.xlsx`,
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                )
            }
        } catch (err) {
            console.warn('Error while fetching download PDF link for inspector data. ', err)
        }
        if (!success) {
            window.$nuxt.$toast.error(window.$nuxt.$t('form.responses.download_failed'))
        }

        let res
        if (links.length > 0) {
            res = await filesDownload(links)
        }
        return res
    }

    /**
     * Upload image for an entity
     *
     * atm API only supports {Site} entity
     *
     * @param {FormData} data
     * @param {Object} config
     *
     * @returns {Promise}
     */
    uploadEntityImg(data, config) {
        const uri = `${this.$config.axios.entitiesImagesURL}/api/upload`
        return this.$axios.$post(uri, data, config)
    }

    async getDuosCost(data) {
        const uri = `${this.$config.axios.costServiceURL}/cost`
        try {
            return await this.$axios.$post(uri, data)
        } catch (err) {
            console.warn('Error while fetching duos cost data. ', err)
        }
    }

    getRag(gspGroup) {
        const uri = `${this.$config.axios.marketBaseURL}/uk/rag/voltage/low/${gspGroup}`
        try {
            return this.$axios.$get(uri)
        } catch (err) {
            console.warn('Error while fetching rag data. ', err)
        }
    }

    async getRates(params) {
        const uri = `${this.$config.axios.marketBaseURL}/uk/rates/duos`
        try {
            return await this.$axios.$get(uri, { params })
        } catch (err) {
            console.warn('Error while fetching rates data. ', err)
        }
    }
}
