import { statusCodes } from '~/utils/api'
import { getCommodityByRoute, knownCommodities } from '~/utils/commodities'

export default function({ route, error }) {
    if (route.meta.some(meta => meta.isCommodity)) {
        const { commodity } = getCommodityByRoute(route.path)
        if (!commodity || !knownCommodities.includes(commodity)) {
            return error({ statusCode: statusCodes.NOT_FOUND })
        }
    }
}
