import isEqual from 'lodash/isEqual'

/**
 * Returns the set difference of B and A, i.e. the set of elements in B but not in A
 */
export function arrayDiff(a, b) {
    const diff = []
    for (let i = 0; i < b.length; i++) {
        if (!a.includes(b[i])) diff.push(b[i])
    }
    return diff
}

export function deepEqual(a, b) {
    if (a === b) return true

    if (a instanceof Date && b instanceof Date) {
        // If the values are Date, they were convert to timestamp with getTime and compare it
        if (a.getTime() !== b.getTime()) return false
    }

    if (a !== Object(a) || b !== Object(b)) {
        // If the values aren't objects, they were already checked for equality
        return false
    }

    // when a &b are arrays
    if (Array.isArray(a) && Array.isArray(b)) {
        return isEqual([...a].sort(), [...b].sort())
    }

    const props = Object.keys(a)

    if (props.length !== Object.keys(b).length) {
        // Different number of props, don't bother to check
        return false
    }

    return props.every(p => deepEqual(a[p], b[p]))
}

export function getNestedValue(obj, path = [], fallback) {
    const last = path.length - 1

    if (last < 0) return obj === undefined ? fallback : obj

    for (let i = 0; i < last; i++) {
        if (obj == null) {
            return fallback
        }
        obj = obj[path[i]]
    }

    if (obj == null) return fallback

    return obj[path[last]] === undefined ? fallback : obj[path[last]]
}

export function getObjectValueByPath(obj, path, fallback) {
    // credit: http://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-with-string-key#comment55278413_6491621
    if (obj == null || !path || typeof path !== 'string') return fallback
    if (obj[path] !== undefined) return obj[path]
    path = path.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
    path = path.replace(/^\./, '') // strip a leading dot
    return getNestedValue(obj, path.split('.'), fallback)
}

export function filterTreeItem(item = {}, search = '', textKey = '') {
    const text = getObjectValueByPath(item, textKey)
    return text.toLocaleLowerCase().includes(search.toLocaleLowerCase())
}

export function filterTreeItems(
    filter = filterTreeItem,
    item = {},
    search = '',
    idKey = '',
    textKey = '',
    childrenKey = '',
    excluded = [],
) {
    if (filter(item, search, textKey)) {
        return true
    }
    const children = getObjectValueByPath(item, childrenKey)

    if (children) {
        let match = false
        for (let i = 0; i < children.length; i++) {
            if (filterTreeItems(filter, children[i], search, idKey, textKey, childrenKey, excluded)) {
                match = true
            }
        }

        if (match) return true
    }

    excluded.add(getObjectValueByPath(item, idKey))

    return false
}

export function createRange(length) {
    return Array.from({ length }, (v, k) => k)
}

export function isCssColor(color = '') {
    return !!color && !!color.match(/^(#|var\(--|(rgb|hsl)a?\()/)
}

export function hasChildren(node) {
    return node && node.children && node.children.length > 0
}

export function hasSeparateChildrenToggle(node) {
    return hasChildren(node) && node.item.activatable && (['presets', 'meters', 'clamps'].includes(node.item.entity) ||
        (node.item.entity === 'sites' && node.parent?.includes('presets')))
}

export function childrenHaveData(children) {
    return children.length && children.some(c => c.hasData)
}
