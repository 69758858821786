/* eslint-disable no-mixed-operators */
import Queue from 'promise-queue'
import { get, groupBy, orderBy, minBy, maxBy, sortBy } from 'lodash'
import pDebounce from 'p-debounce'

import Unit from '../models/Unit'
import { extraColors } from '~/config/colors'
import { parentSort, transformAggregates } from '~/utils/calculations'
import { VALID_RESOLUTIONS } from '~/utils/resolution'
import { calculateRequestEntitiesIds } from '~/utils/entities'
import { getScopeByCommodity } from '~/utils/commodities'
import { FROM_ROOT } from '~/utils/state'

export const state = {
    /**
     * Cached items keys array
     *
     * This is essentially the array of props in loaded items of the cache service
     * which main purpose of having it here is the reactivity
     * for start this is used in the pipes list to visually show
     * when a pipe has data loaded for selected unit
     *
     * @type {Array}
     */
    cached: [],

    areSetup: {},
    areVirtualSetup: {},
}

export const getters = {
    treeview(_state, _getters, _rootState, rootGetters) {
        return rootGetters['entities/treeviews/active']
    },

    allByCommodity(state, getters) {
        return commodity => getters.query()
            .where(e => e.commodity === commodity)
            .get()
    },

    allVirtualByCommodity(state, getters) {
        return commodity => getters.query()
            .where(e => e.commodity === commodity)
            .where(e => e.virtual)
            .get()
    },

    selected() {
        return []
    },

    /**
     * The selected getter returns the active elements if there are some
     * This getter forces to return the real selected ones
     */
    selectedForced(_state, getters) {
        const selectedIds = get(getters, 'treeview.selectedEndIds', [])

        return getters
            .query()
            .where(p => {
                return selectedIds.includes(p.id) ||
                    selectedIds.includes(p.presetId) && p.virtual ||
                    selectedIds.includes(p.meterId) && p.isMainIncomer
            }).get()
    },

    selectedIds(_state, getters) {
        return getters.selected.map(p => p.id)
    },

    ids(state, getters) {
        return getters.all().map(p => p.id)
    },

    realIds(state, getters) {
        return getters.real.map(p => p.id)
    },

    virtualIds(state, getters) {
        return getters.virtual.map(p => p.id)
    },

    sorted(state, getters) {
        return getters.query()
            .orderBy('childrenCount', 'desc')
            .orderBy('totalKwData', 'desc')
            .get()
    },

    haveData(state, getters) {
        return commodity => getters.query()
            .where(e => !e.virtual && e.commodity === commodity)
            .orderBy('childrenCount', 'desc')
            .get()
    },

    haveStartEndTimes(state, getters) {
        return commodity => getters.haveData(commodity).filter(p => p.startTime && p.endTime)
    },

    startTimeSortedList(_state, getters, rootState) {
        return sortBy(getters.haveStartEndTimes(rootState.clamp.commodity), ['startTime'])
    },

    minResolution(_state, getters, rootState) {
        const minimumResolution = minBy(getters.haveData(rootState.clamp.commodity).filter(c => !c.isNHH), 'sampleRate')?.sampleRate
        if (rootState.clamp.commodity === 'power' && minimumResolution) {
            return minimumResolution
        }
        return !minimumResolution ? VALID_RESOLUTIONS._15_MINUTES : (minimumResolution < VALID_RESOLUTIONS._15_MINUTES ? VALID_RESOLUTIONS._15_MINUTES : minimumResolution)
    },

    initialResolution(state, getters, rootState) {
        const maxResolution = maxBy(getters.haveData(rootState.clamp.commodity), 'sampleRate')?.sampleRate
        return maxResolution < VALID_RESOLUTIONS._15_MINUTES ? VALID_RESOLUTIONS._15_MINUTES : maxResolution
    },

    /**
     * Get main incomer
     * @param  {Object} state
     * @param  {Object} getters
     * @return {Clamp}
     */
    main(state, getters) {
        return getters.query().where('isMainIncomer', true).first()
    },

    real(state, getters) {
        return getters.query().where('virtual', false).get()
    },

    virtual(state, getters) {
        return getters.query().where('virtual', true).get()
    },

    /**
     * Get main incomer
     * @param  {Object} state
     * @param  {Object} getters
     * @return {Clamp}
     */
    mainOrFallback(state, getters, rootState) {
        return getters.main || getters.haveData(rootState.clamp.commodity)[0] || null
    },

    /**
     * Get all the main incomers
     * @param state
     * @param getters
     * @returns {{length}|*|*[]}
     */
    mains(state, getters) {
        return getters.query().where('isMainIncomer', true).get()
    },

    /**
     * Get all the main incomers
     * @param state
     * @param getters
     * @returns {{length}|*|*[]}
     */
    mainsOrFallback(state, getters, rootState) {
        return getters.mains || getters.haveData(rootState.clamp.commodity)[0] ? [getters.haveData(rootState.clamp.commodity)[0]] : []
    },

    hasMainIncomer(state, getters) {
        return getters.main && getters.main.isMainIncomer
    },

    onlyMainIncomers: (state, getters, rootState) => {
        return getters.query()
            .where('isMainIncomer', true)
            .where('virtual', false)
            .where('commodity', rootState.clamp.commodity)
            .get()
    },

    isThereAtLeastOneMeterWithNoMainIncomer: (_state, _getters, rootState, rootGetters) => {
        let isThereAtLeastOneMeterWithNoMainIncomer = false
        const meter = rootGetters['entities/meters/allByCommodity']
        meter(rootState.clamp.commodity).forEach(meter => {
            if (!meter.children.find(e => e.isMainIncomer)) {
                isThereAtLeastOneMeterWithNoMainIncomer = true
            }
        })
        return isThereAtLeastOneMeterWithNoMainIncomer
    },
}

export const mutations = {
    SET_ARE_SETUP(state, { commodity, value }) {
        state.areSetup[commodity] = value
    },

    SET_ARE_VIRTUAL_SETUP(state, { commodity, value }) {
        state.areVirtualSetup[commodity] = value
    },

    SET_CACHED(state, items) {
        state.cached = items
    },
}

export const actions = {
    /**
     * Setup pipes
     *
     * will setup the following params for each pipe
     * color
     *
     * @param {Object} context
     * @return {Void}
     */
    setup: pDebounce(async function({ commit, dispatch, getters, state, rootGetters }, { forced = false, commodity } = {}) {
        if (!forced && state.areSetup[commodity]) return
        const data = []

        const groupedByMeter = groupBy(getters.allByCommodity(commodity).filter(({ meterId }) => meterId), 'meterId')
        const { entityColors, mainEntityColors } = extraColors(rootGetters.theme)
        let colorIndex = 0
        let mainColorIndex = 0
        await dispatch('fetchCircuitsRankings', true, FROM_ROOT)
        const clampsExtraInfo = rootGetters.rankingClamps
        for (const meterId in groupedByMeter) {
            let clamps = groupedByMeter[meterId].map(clamp => (
                {
                    ...clamp,
                    ...(clampsExtraInfo.get(clamp.id) || {}),
                    hasData: !!(clampsExtraInfo.get(clamp.id) && clampsExtraInfo.get(clamp.id).E !== null),
                }
            ))
            clamps = orderBy(clamps, 'totalKwData', 'desc')
            clamps = parentSort(clamps)
            if (!clamps) continue
            const newData = clamps.map(clamp => {
                if (clamp.isMainIncomer) {
                    clamp.color = clamp.color || mainEntityColors[mainColorIndex]
                    clamp.backupColor = mainEntityColors[mainColorIndex]
                    mainColorIndex++
                    if (!mainEntityColors[mainColorIndex]) {
                        mainColorIndex = 0
                    }
                } else {
                    clamp.color = clamp.color || entityColors[colorIndex]
                    clamp.backupColor = entityColors[colorIndex]
                    colorIndex++
                    if (!entityColors[colorIndex]) {
                        colorIndex = 0
                    }
                }

                return clamp
            })
            data.push(...newData)
        }
        await dispatch('insert', { data })
        commit('SET_ARE_SETUP', { commodity, value: true })
    }, 200),

    /**
     * Setup virtual pipes
     *
     * will setup the following params for each pipe
     * startTime, endTime, totalKwData, color
     *
     * ! for virtual pipes we are not fetching aggregates here becasue is taking too long
     *
     *
     * @param {Object} context
     * @param {Object} payload
     * @returns {Promise}
     */
    setupVirtual: pDebounce(async function({ commit, state, dispatch, getters, rootGetters }, { forced = false, commodity } = {}) {
        if (!forced && state.areVirtualSetup[commodity]) return
        const colors = extraColors(rootGetters.theme).virtualEntityColors
        let colorIndex = 0
        const data = getters.allByCommodity(commodity).map(clamp => {
            if (clamp.virtual) {
                const siblings = clamp.siblings
                const totalKwData = this.$_.sumBy(siblings, 'totalKwData')
                const startTime = this.$_.get(this.$_.minBy(siblings, 'startTime'), 'startTime')
                const endTime = this.$_.get(this.$_.maxBy(siblings, 'endTime'), 'endTime')
                const color = clamp.color || colors[colorIndex]
                const backupColor = colors[colorIndex++]
                if (!colors[colorIndex]) colorIndex = 0 // reset color index when exceeded

                return {
                    ...clamp,
                    totalKwData,
                    startTime,
                    endTime,
                    color,
                    backupColor,
                }
            }

            return { ...clamp }
        })
        await dispatch('insert', { data })
        commit('SET_ARE_VIRTUAL_SETUP', { commodity, value: true })
    }, 200),

    /**
     * Set aggregates
     *
     * @param {*} storeCtx
     *
     * @returns {Void}
     */
    async setAggregates({ dispatch, getters }) {
        // fetch and get aggregates
        const aggregates = await dispatch('fetchAggregates')
        const data = getters.all().map(pipe => {
            if (!pipe.virtual) { // Math.abs(pipe.totalKwData) > 0 &&
                return {
                    ...pipe,
                    aggregates: aggregates[pipe.id] || {},
                }
            }
            return { ...pipe }
        })
        await dispatch('insert', { data })
    },

    /**
     * Fetch aggregates
     *
     * @param {Object} storeContext
     *
     * @returns {Void}
     */
    async fetchAggregates({ getters, rootState, rootGetters }) {
        const params = Unit.params(['GVOL', 'U_GENE', 'C_GENE', 'GCAL'])
        const { period } = rootState
        const entities = getters.query().where('virtual', false).where(e => e.hasData).get()
        const payload = {
            precision: 2,
            splitCircuits: true,
            max: params,
            clampIds: calculateRequestEntitiesIds(entities, getScopeByCommodity(rootState.clamp.commodity).long).clampIds,
            resolution: period.resolution,
            daterange: rootGetters['period/maxRange'],
            for: 'setup',
        }

        const aggregates = {}
        try {
            // fetch aggregates data
            const { data } = await this.$api.clickHouse.fetchAggregates(payload)

            // map data to our preferences
            for (const circuitId in data.clamps) {
                aggregates[circuitId] = transformAggregates(data.clamps[circuitId])
            }
        } catch (err) {
            console.warn('Error while fetching clamps aggregates. ', err.message)
        }
        return aggregates
    },

    /**
     * Set virtual aggregates
     *
     * @param {*} storeCtx
     *
     * @returns {Void}
     */
    async setVirtualAggregates({ dispatch, getters }) {
        // set a queue for the virtual clamps aggregates
        const queue = new Queue(2, Infinity)

        const promises = getters.virtual.map(async clamp => {
            // fetch clamp aggregates
            const aggregates = await queue.add(() => dispatch('fetchCircuitAggregates', clamp))
            return {
                ...clamp,
                aggregates,
            }
        })
        // await promises
        const data = [
            ...getters.real,
            ...await Promise.all(promises),
        ]
        // update virtual clamp
        await dispatch('insert', { data })
    },

    /**
     * Fetch aggregates per circuit
     *
     * @param {Object} storeContext
     *
     * @returns {Void}
     */
    async fetchCircuitAggregates({ rootState }, circuit) {
        const params = Unit.params()
        const { period } = rootState
        const entities = circuit.virtual
            ? circuit.siblings
            : [circuit]

        const payload = {
            precision: 2,
            max: params,
            clampIds: calculateRequestEntitiesIds(entities).clampIds,
            resolution: period.resolution,
            daterange: [
                this.$moment(period.min).format(),
                this.$moment(period.max).format(),
            ],
            for: 'setup',
        }

        let aggregates = {}
        try {
            // fetch aggregates data for each virtual circuit
            const res = await this.$api.clickHouse.fetchAggregates(payload)
            if (res?.data) {
                aggregates = transformAggregates(res.data)
            }
        } catch (err) {
            console.warn('Error while fetching circuits aggregates. ', err.message)
        }

        return aggregates
    },
}
