/* eslint-disable no-mixed-operators */
// Utils
import uniqBy from 'lodash/uniqBy'
import { idFromEntity, parseEUID, parseEUIDs } from '~/utils/entities'

export default {
    entityQuery: (_state, _getters, _rootState, rootGetters) => entity => {
        return rootGetters[`entities/${entity}/query`]()
    },

    entityModel: (_state, getters) => entity => {
        return getters.entityQuery(entity).getModel()
    },

    sites(_state, getters) {
        return getters.entityQuery('sites').with('meters.clamps').all()
    },

    allMetersHasMainIncomer(state, _getters, _rootState, rootGetters) {
        const allMeters = rootGetters['entities/meters/allByCommodity'](state.commodity)
        const allMainIncomers = rootGetters['entities/clamps/onlyMainIncomers']
        return allMeters.length === allMainIncomers.length
    },

    activeEntities: (state, getters) => (type = 'all') => {
        const euids = state.activeEntities[type]
        return euids.map(euid => {
            const { entity, id } = parseEUID(euid, true)
            return getters.entityQuery(entity).find(id)
        })
    },

    filteredEUIDs: state => ({
        stand = 'selected',
        type = 'all',
        entities = ['clamps', 'meters', 'presets'],
    } = {}) => {
        return state[`${stand}Entities`][type].filter(euid => {
            for (const entity of entities) {
                if (euid.includes(entity)) return true
            }
            return false
        })
    },

    activeEUIDs: state => (type = 'all') => {
        return state.activeEntities[type].filter(euid => {
            return euid.includes('clamps') || euid.includes('meters') || euid.includes('presets')
        })
    },

    selectedEUIDs: state => (type = 'all') => {
        return state.selectedEntities[type].filter(euid => {
            return euid.includes('clamps') || euid.includes('meters') || euid.includes('presets')
        })
    },

    euids: (_state, getters) => (type = 'all') => {
        const activeEUIDs = getters.activeEUIDs(type)
        if (activeEUIDs.length > 0) return activeEUIDs
        return getters.selectedEUIDs(type)
    },

    activeClamps: (state, getters, rootState) => (type = 'all') => {
        const euids = state.activeEntities[type]
        const parsedEuids = parseEUIDs(euids, { reverse: true })
        const clampsIds = parsedEuids.filter(({ entity }) => entity === 'clamps').map(idFromEntity)
        const metersIds = parsedEuids.filter(({ entity }) => entity === 'meters').map(idFromEntity)
        const presetsIds = parsedEuids.filter(({ entity }) => entity === 'presets').map(idFromEntity)

        return getters
            .entityQuery('clamps')
            .where(c => {
                return c.hasData && c.commodity === rootState.clamp.commodity && (
                    clampsIds.includes(c.id) ||
                    presetsIds.includes(c.presetId) && c.virtual ||
                    metersIds.includes(c.meterId) && c.isMainIncomer
                )
            }).get()
    },

    selectedClamps: (state, getters, rootState) => (type = 'all', forceSelected = false) => {
        let euids = forceSelected ? state.selectedEntities[type] : state.activeEntities[type]
        if (euids.length === 0) {
            euids = state.selectedEntities[type]
        }

        const parsedEuids = parseEUIDs(euids, { reverse: true })
        const clampsIds = parsedEuids.filter(({ entity }) => entity === 'clamps').map(idFromEntity)
        const metersIds = parsedEuids.filter(({ entity }) => entity === 'meters').map(idFromEntity)
        const sitesIds = parsedEuids.filter(({ entity }) => entity === 'sites').map(idFromEntity)
        const presetsIds = parsedEuids.filter(({ entity }) => entity === 'presets').map(idFromEntity)
        const clamps = getters
            .entityQuery('clamps')
            .where(c => {
                return c.hasData && rootState.clamp.commodity === c.commodity && (
                    clampsIds.includes(c.id) ||
                    presetsIds.includes(c.presetId) && c.virtual ||
                    metersIds.includes(c.meterId) && c.isMainIncomer
                )
            }).get()

        return rootState.clamp.tabIndex !== '0' && sitesIds.length
            ? [...clamps, ...getters
                .entityQuery('sites')
                .whereIdIn(sitesIds).get()]
            : clamps
    },

    selectedClampsMeters: (_state, getters) => (type = 'all') => {
        const selectedClamps = getters.selectedClamps(type)
        const meters = selectedClamps.filter(e => !e.virtual).map(c => c.relationships.meter || c.relationships.meters)
        return uniqBy(meters, 'id')
    },

    clampsMeters: () => clamps => {
        if (!clamps) return []
        const meters = clamps.filter(e => !e.virtual).flatMap(c => c.relationships.meter || c.relationships.meters).filter(m => m)
        return uniqBy(meters, 'id')
    },

    validatedTreeview: (_state, _getters, _rootState, rootGetters) => treeview => {
        const validatedEntities = euids => euids
            ? euids.filter(euid => {
                const { entity, id } = parseEUID(euid, { reverse: true })
                const getterKey = `entities/${entity}/find`
                return typeof rootGetters[getterKey] === 'function' ? !!rootGetters[getterKey](id) : false
            })
            : []
        return {
            ...treeview,
            selected: validatedEntities(treeview.selected),
            active: validatedEntities(treeview.active),
        }
    },
}
