// import moment from '~/composables/useMoment'

export const initialState = (payload = {}) => ({
    initialised: false,
    start: null,
    end: null,
    min: null,
    max: null,
    interval: null,
    resolution: 15 * 60, // 15 min
    shift: null,
    absoluteComparison: false,
    ...payload,
    periodChanged: null,
})

export default () => ({
    ...initialState(),
})
