// ORM Models
import { get, sumBy, orderBy, take } from 'lodash'
import Unit from '~/orm/models/Unit'
// Utils
import { idKey, consolidateEntities, getEuids, getIds, addScopeToPayload } from '~/utils/entities'
import { dynamicResolution } from '~/utils/resolution'
import moment from '~/composables/useMoment'

const SITES_BREAKDOWN_LENGTH = 2

export default {
    sites(_state, _getters, _rootState, rootGetters) {
        return rootGetters['entities/sites/query']().where('isUserGenerated', false).with(['meters.clamps']).get()
    },

    siteById: (_state, getters) => id => {
        return getters.sites.find(s => s.id === id)
    },

    sitesByIds: (_state, getters) => (ids = []) => {
        return getters.sites.filter(s => ids.includes(s.id))
    },

    activeSiteIds(state) {
        if (state.active.entity !== 'sites') { return [] }
        return state.active.ids
    },

    activeSites(_state, getters) {
        return getters.sites.filter(site => getters.activeSiteIds.includes(site.id))
    },

    selectedSiteIds(state) {
        if (state.selected.entity !== 'sites') { return [] }
        return state.selected.ids
    },

    selectedSites(_state, getters) {
        return getters.sites.filter(site => getters.selectedSiteIds.includes(site.id))
    },

    presets(state, _getters, _rootState, rootGetters) {
        if (state.commodity) {
            return rootGetters['entities/presets/query']().where('commodity', state.commodity).get()
        }
        return rootGetters['entities/presets/query']().get()
    },

    activePresetIds(state) {
        if (state.active.entity !== 'presets') { return [] }
        return state.active.ids
    },

    activePresets(_state, getters) {
        return getters.presets.filter(preset => getters.activePresetIds.includes(preset.id))
    },

    selectedPresetIds(state) {
        if (state.selected.entity !== 'presets') { return [] }
        return state.selected.ids
    },

    selectedPresets(_state, getters) {
        return getters.presets.filter(preset => getters.selectedPresetIds.includes(preset.id))
    },

    selectedMeters(state, getters) {
        if (state.baseEntity === 'presets') {
            return getters.selectedPresets.map(preset => preset.relationships.meters ?? []).flat()
        }
        return getters.selectedSites.map(s => s.meters).flat()
    },

    selectedClamps(state, getters) {
        if (state.baseEntity === 'presets') {
            return getters.selectedPresets.map(preset => preset.relationships.clamps ?? []).flat()
        }
        return getters.selectedMeters.map(m => m.clamps).flat()
    },

    selectedClampsIds(_state, getters) {
        return getIds(getters.selectedClamps)
    },

    entity(state) {
        if (state.active.ids.length > 0) { return state.active.entity }
        if (state.selected.ids.length <= SITES_BREAKDOWN_LENGTH) { return 'clamps' }
        return state.selected.entity
    },

    selectedEntities(state, getters) {
        if (state.selected.ids.length <= SITES_BREAKDOWN_LENGTH) {
            return getters.selectedClamps
        }
        if (!state.commodity && state.selected.ids.length <= SITES_BREAKDOWN_LENGTH) {
            return getters.selectedClamps
        }

        if (state.baseEntity === 'presets') {
            return getters.selectedPresets
        }
        return getters.selectedSites
    },

    entityIds(_state, getters) {
        return getIds(getters.selectedEntities)
    },

    entityIdsKey(_state, getters) {
        return idKey(getters.entity)
    },

    entityModel: (_state, _getters, _rootState, rootGetters) => ({ entity, id }) => {
        if (entity === 'clamps') {
            return rootGetters['entities/clamps/find'](id)
        }
        return rootGetters[`entities/${entity}/find`](id)
    },

    /**
     *  Get EUIDs
     * needed for updating hash / investigate further feature
     *
     * @return  {Array} euids
     */
    selectedEUIDs: (state, getters) => commodity => {
        let meters = []
        if (state.baseEntity === 'presets') {
            meters = getters.selectedPresets
        } else {
            meters = getters.selectedMeters
        }
        return getEuids(commodity ? meters.filter(m => m.commodity === commodity) : meters)
    },

    kvaThreshold(state, getters) {
        return sumBy(getters.selectedMeters.filter(m => m.commodity === (state.commodity || 'power')), 'kvaThreshold')
    },

    /**
     * Consolidated entities
     * so that we don't include both parents and children
     * logic as per CLAL-385 ticket requirements
     *
     * @returns {Array} entities
     */
    consolidatedEntities(_state, getters, rootState) {
        const { period } = rootState
        return consolidateEntities(getters.selectedEntities, period)
    },

    /**
     * Consolidated entities IDs
     *
     * @returns {Array} entityIds
     */
    consolidatedEntityIds(_state, getters) {
        return getIds(getters.consolidatedEntities)
    },

    params(state) {
        return Unit.commodityParams(state.commodity)
    },

    commodityUnits: () => commodity => {
        return Unit.forCommodity(commodity)
    },

    units(state, getters) {
        return getters.commodityUnits(state.commodity)
    },

    unit(state) {
        return Unit.find(state.unitId)
    },

    kvaUnit() {
        return Unit.find('kva')
    },

    unitSummaryById: state => unitId => {
        return state.unitSummaries.find(u => u.id === unitId)
    },

    unitSummaryByParam: state => param => {
        return state.unitSummaries.find(u => u.param === param)
    },

    unitSummary(state, getters) {
        return getters.unitSummaryById(state.unitId)
    },

    kvaSummary(_state, getters) {
        return getters.unitSummaryById('kva')
    },

    kvaValue(_state, getters) {
        return get(getters, 'kvaSummary.extra.value', 0) / 1e3
    },

    dynamicResolution(_state, _getters, rootState) {
        return dynamicResolution(rootState.period.end, rootState.period.start)
    },

    unitAggregates(state, getters) {
        const unit = getters.unit
        // Filter and order
        const by = `${unit.dataAggregation}.${unit.param}.value`
        const order = unit.id === 'pf' ? 'asc' : 'desc'
        const aggregates = state.aggregates.filter(item => ![null, 0].includes(get(item, by, null)))
        return orderBy(aggregates, by, order)
    },

    unitAggregatesWithoutMainIncomers(_state, getters) {
        return getters.unitAggregates.filter(item => !(item.entity === 'clamps' && item.commodity === 'power' && item.isMainIncomer))
    },

    topFiveEntitiesByAggregate(state, getters) {
        return take(getters.unitAggregatesWithoutMainIncomers, 5)
    },

    topTenEntitiesByAggregate(state, getters) {
        return take(getters.unitAggregatesWithoutMainIncomers, 10)
    },

    ids(state, getters) {
        return state.active.ids.length > 0 ? state.active.ids : getters.entityIds // getters.consolidatedEntityIds
    },

    aggregatesPayload(_state, getters) {
        return Unit.aggregatesPayload(getters.units)
    },

    extraAggregatesPayload() {
        return {
            ...Unit.aggregatesPayload([Unit.find('kva')]),
            sum: ['C_DUXCAP'], // needed for kVA excess cost calculation
            avg: ['PF'],
            scopes: ['ELEC'],
        }
    },

    allEntitiesPayload(state, getters) {
        const payload = {
            [getters.entityIdsKey]: getters.entityIds,
        }
        addScopeToPayload(payload, state.commodity)
        return payload
    },

    entitiesPayload(state, getters) {
        const payload = {
            [getters.entityIdsKey]: getters.ids,
        }
        addScopeToPayload(payload, state.commodity)
        return payload
    },

    filterClampIdsByCommodity: (_state, getters) => (clampIds = [], commodity) => {
        return commodity ? clampIds.filter(id => getters.selectedClampsIds.includes(id)) : clampIds
    },

    isComparingSamePeriod(state, _getters, rootState) {
        return state.comparison && moment(rootState.period.start).isSame(state.comparison.start) &&
            moment(rootState.period.end).isSame(state.comparison.end)
    },

    disabledCommodities(state, getters) {
        return state.commodities.filter(c => c && state.selected.entity === 'presets' &&
            !getters.presets.filter(p => state.selected.ids.includes(p.id) && p.commodity === c).length)
    },
}
