export const FeatureNotSupported = () => import('../../components/templates/FeatureNotSupported.vue' /* webpackChunkName: "components/feature-not-supported" */).then(c => wrapFunctional(c.default || c))
export const SmartTreeview = () => import('../../components/templates/SmartTreeview.vue' /* webpackChunkName: "components/smart-treeview" */).then(c => wrapFunctional(c.default || c))
export const ThePageSkeleton = () => import('../../components/templates/ThePageSkeleton.vue' /* webpackChunkName: "components/the-page-skeleton" */).then(c => wrapFunctional(c.default || c))
export const ThePageToolbar = () => import('../../components/templates/ThePageToolbar.vue' /* webpackChunkName: "components/the-page-toolbar" */).then(c => wrapFunctional(c.default || c))
export const TheSidebarSkeleton = () => import('../../components/templates/TheSidebarSkeleton.vue' /* webpackChunkName: "components/the-sidebar-skeleton" */).then(c => wrapFunctional(c.default || c))
export const TheTreeviewsSkeleton = () => import('../../components/templates/TheTreeviewsSkeleton.vue' /* webpackChunkName: "components/the-treeviews-skeleton" */).then(c => wrapFunctional(c.default || c))
export const ChartPlaceholder = () => import('../../components/placeholders/ChartPlaceholder.vue' /* webpackChunkName: "components/chart-placeholder" */).then(c => wrapFunctional(c.default || c))
export const CircuitsListPlaceholder = () => import('../../components/placeholders/CircuitsListPlaceholder.vue' /* webpackChunkName: "components/circuits-list-placeholder" */).then(c => wrapFunctional(c.default || c))
export const DataTablePlaceholder = () => import('../../components/placeholders/DataTablePlaceholder.vue' /* webpackChunkName: "components/data-table-placeholder" */).then(c => wrapFunctional(c.default || c))
export const PageToolbarPlaceholder = () => import('../../components/placeholders/PageToolbarPlaceholder.vue' /* webpackChunkName: "components/page-toolbar-placeholder" */).then(c => wrapFunctional(c.default || c))
export const TerminalPlaceholder = () => import('../../components/placeholders/TerminalPlaceholder.vue' /* webpackChunkName: "components/terminal-placeholder" */).then(c => wrapFunctional(c.default || c))
export const BtnGroup = () => import('../../components/global/BtnGroup.vue' /* webpackChunkName: "components/btn-group" */).then(c => wrapFunctional(c.default || c))
export const ThemeSwitch = () => import('../../components/global/ThemeSwitch.vue' /* webpackChunkName: "components/theme-switch" */).then(c => wrapFunctional(c.default || c))
export const VSubtitle = () => import('../../components/global/VSubtitle.vue' /* webpackChunkName: "components/v-subtitle" */).then(c => wrapFunctional(c.default || c))
export const InfoMarkBackup = () => import('../../components/global/info-mark-backup.vue' /* webpackChunkName: "components/info-mark-backup" */).then(c => wrapFunctional(c.default || c))
export const InfoMark = () => import('../../components/global/info-mark.vue' /* webpackChunkName: "components/info-mark" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
