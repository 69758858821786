// Utils
import { initialState } from './state'
import { dateRadius, MONTH } from '~/utils/date'
import { FROM_ROOT } from '~/utils/state'
import { parseEUIDs } from '~/utils/entities'
import { VALID_RESOLUTIONS, getResolutionValue } from '~/utils/resolution'
import Clamp from '~/orm/models/Clamp'

export default {
    /**
     * Init period asap on client side
     *  - here we are setting min, max, start, end in a certain priority
     *  - url hash > entity dates > default values
     *  - and remaping all date strings to moment objects
     *
     *
     * @param {Object} storeContext
     * @param {Object} payload
     * @param entity
     */
    init({ commit }, payload = {}) {
        // if (state.initialised) return
        if (this.$router.currentRoute.hash && this.$hashState.period) {
            payload = Object.assign({}, this.$hashState.period, payload)
        }

        const initialPeriod = initialState(payload)
        this.$PeriodService.updateInit(initialPeriod)
        commit('UPDATE', initialPeriod)
        commit('INIT')
    },

    /**
     * Update Period
     * @param  {Function} options.commit
     * @param  {Object} period
     * @return {Void}
     */
    update({ commit, state, dispatch }, period) {
        period = { ...period }
        if (period.interval === null || (period.interval && period.interval !== state.interval)) {
            // signal comparison to deactivate
            dispatch('clamp/setComparison', null, FROM_ROOT)
            dispatch('dashboard/setComparison', null, FROM_ROOT)
        }
        commit('UPDATE', this.$_.omit(period, 'by'))
        commit('SET_PERIOD_CHANGED')
    },

    /**
     * Reset Period
     *
     * @param {Object} storeContext
     * @param {Object} payload
     * @return {Void}
     */
    reset({ dispatch }, payload = {}) {
        this.$PeriodService.updatePeriodWithDebounce(initialState({ ...this.$PeriodService.initialState, ...payload }), p => dispatch('update', p))
    },

    /**
     * Zoom into time point
     * @param dispatch
     * @param getters
     * @param state
     * @param  {Date} date
     * @param span
     * @return Void
     */
    zoomIntoTime({ dispatch, getters, state }, { date }) {
        if (getters.isCurrentResolutionStacking) {
            // adding a couple of hours to make sure i'm in the right day
            let start, end
            switch (state.period.resolution) {
                case 'day':
                case 86.4e3:
                    start = this.$moment(date).startOf('day').subtract(3, 'days')
                    end = this.$moment(date).endOf('day').add(3, 'days')
                    break
                case 'week':
                    return null
                case 'month':
                    return null
                case 'year':
                    start = this.$moment(date).startOf('year')
                    end = this.$moment(date).endOf('year')
                    break
            }
            this.$PeriodService.updatePeriodWithDebounce({
                start,
                end,
            }, p => dispatch('update', p))
        } else {
            // zoom into 12 hours radius
            const { start, end } = dateRadius(date, 60 * 60 * 12)
            this.$PeriodService.updatePeriodWithDebounce({
                start,
                end,
            }, p => dispatch('update', p))
        }
    },

    checkMinResolution({ rootGetters, dispatch, rootState }, { euids }) {
        const entities = parseEUIDs(euids, { reverse: true }).flatMap(({ entity: entityType, id }) => {
            const entity = rootGetters[`entities/${entityType}/find`](id)
            if (['meters'].includes(entityType) && entity.mainIncomer) {
                return entity.mainIncomer
            }
            if (entityType === 'sites') {
                return Clamp.query().where('siteId', Number(id)).where('isMainIncomer', true).where('commodity', rootState.clamp.commodity).get()
            }
            return entity
        }).filter(e => e)

        if (entities?.length) {
            let minimumResolution = entities.some(e => e.isNHH)
                ? MONTH
                : Math.max(...entities.map(e => e.sampleRate || VALID_RESOLUTIONS._30_MINUTES))
            // if it's VALID_RESOLUTIONS.MONTH resolution we need to use the MONTH to compare them
            const currentResolution = this.$_.isNumber(rootState.period.resolution)
                ? rootState.period.resolution
                : getResolutionValue(rootState.period.resolution)
            if (currentResolution < minimumResolution) {
                if (minimumResolution === MONTH) {
                    minimumResolution = VALID_RESOLUTIONS.MONTH
                }
                this.$PeriodService.updatePeriodWithDebounce({ resolution: minimumResolution }, p => dispatch('update', p))
                if (rootState.clamp.initialised) {
                    this.$toast.info('Resolution has been changed since it was not supported by the selected circuits')
                }
            }
        }
    },
}
