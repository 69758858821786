/* Models */
import { Model } from '@vuex-orm/core'
import { get, intersection, orderBy } from 'lodash'
import { n } from '~/plugins/filters'
import { COMMODITY_PARAMS, COMMODITY_UNITS } from '~/utils/commodities'

export default class Unit extends Model {
    #children
    #parent

    static entity = 'units'
    static primaryKey = 'id'
    static COMMODITY_PARAMS = {
        all: ['E', 'U_CO2E', 'C', 'S', 'PF', 'GVOL'],
        ...COMMODITY_PARAMS,
    }

    static CUMULATIVE = 'cumulative'
    static INSTANT = 'instant'
    static POWER = 'power'

    /**
     * retro-compatibility with old hard-coded parameters
     */
    static dataToMerge = currency => COMMODITY_UNITS(currency)

    static apiConfig = {
        url: 'parameters',
        dataKey: 'data',
    }

    static orderMap = [
        'E', 'E1', 'E2', 'E3', 'C', 'P', 'P1', 'P2', 'P3', 'Q', 'Q1', 'Q2', 'Q3', 'S', 'S1', 'S2', 'S3', 'PF', 'PF1', 'PF2', 'PF3', 'V', 'V1', 'V2', 'V3', 'I', 'I1', 'I2', 'I3', 'U', 'U12', 'U23', 'U31', 'RE', 'RE1', 'RE2', 'RE3', 'EX', 'EX1', 'EX2', 'EX3', 'REX', 'REX1', 'REX2', 'REX3', 'U_CO2E', 'U_CO2', 'U_CH4', 'U_N2O', 'A12', 'A13', 'F', 'AE', 'AE1', 'AE2', 'AE3',
    ]

    static fields() {
        return {
            id: this.attr(''),
            param: this.attr(''),
            // symbol: this.attr(''),
            name: this.attr(null),
            allowPreview: this.boolean(false),
            instant: this.boolean(false),
            _multiplier: this.attr(1),
            symbols: this.attr(null),
            dataAggregation: this.attr(''),
            aggregates: this.attr([]),
            scope: this.attr(null),
            hideFromTable: this.boolean(false),
            unitBeforeValue: this.boolean(false),
            disableMultipliers: this.boolean(false),
            // children: this.attr([]),

            // from API:
            key: this.attr(null),
            unit: this.attr(null),
            allowedSampleTypes: this.attr([]),
            description: this.attr(''),
            type: this.attr(null),
            commodity: this.attr(null),
            parentKey: this.attr(null),
            tags: this.attr([]),
            forcedMultiplier: this.attr(null),
            staticMultiplier: this.attr(null),
            manufacturers: this.attr(null),
            originalParam: this.attr(null),
        }
    }

    /**
     * necessary hack for legacy ids, in order to remove this, first need to replace all old ids with the new ones
     * ie: kwh => E, kw => P, etc
     * good luck
     * @param model
     */
    static beforeCreate(model) {
        const disabledDynamicMultiplier = !(this.store().state.settings.general.dynamicUnitScaling === undefined || this.store().state.settings.general.dynamicUnitScaling === true)
        const currency = this.store().getters.currency
        const dataToMerge = Unit.dataToMerge(currency).find(({ param }) => param === model.key)
        if (dataToMerge) {
            for (const [key, value] of Object.entries(dataToMerge)) {
                model[key] = value
            }
        } else {
            model.id = model.key
            model.param = model.key
            model.name = model.description
            // model.symbol = model.unit
        }
        if (!model.disableMultipliers && disabledDynamicMultiplier) {
            model.disableMultipliers = true
            model.forcedMultiplier = model.staticMultiplier ?? 1e3
        }
        model.unit = model.unit
            .replace('{CURRENCY}', this.store().getters.currency.symbol || '£')
            .replace('{CURRENCY_MINOR}', this.store().getters.currency.symbol === '£' ? 'p' : 'c')
    }

    // static async afterCreate(model){
    //     // console.log(model, Unit.all().length)
    //     // model.children = Unit.query().where('parentKey', model.key).get()
    //     const r = await Unit.update({
    //         where: model.key,
    //         data: {
    //             ...model,
    //             children: Unit.query().where('parentKey', model.key).get()
    //         }
    //     })
    //     console.log(r)
    // }

    /**
     * necessary hack to force vuex orm to query by id and not by $id
     * @param id
     * @returns {Instance<Unit>}
     */
    static find(id) {
        switch (id) {
            case 'triad':
            case 'loadFactor':
                id = 'P'
                break
        }
        return Unit.all().find(e => e.id === id || e.key === id)
    }

    static findByParam(param) {
        return this.query().where('param', param).first()
    }

    static params(exclude = []) {
        return this.all()
            .filter(u => Boolean(u.param) && !exclude.includes(u.param))
            .map(u => u.param)
    }

    static idToParam(unitId) {
        return this.find(unitId)?.param
    }

    static idToName(unitId) {
        return this.find(String(unitId).toLowerCase())?.name
    }

    static paramToId(param) {
        return this.findByParam(param)?.id
    }

    static paramToName(param) {
        return this.findByParam(param)?.name
    }

    static commodityParams(commodity = 'all') {
        return this.COMMODITY_PARAMS[commodity]
    }

    static forCommodity(commodity = 'all') {
        return this.COMMODITY_PARAMS[commodity].map(param => this.findByParam(param))
    }

    /**
     * Normalize unit value and symbol
     *
     * > 99999 MWh
     * > 99999999 GWh
     *
     * @returns {Object}
     */
    static normalize(value, unitId, precision = 2, fromSymbol = '', debug = false) {
        if (!value || !unitId) return { value }
        const unit = this.find(unitId)
        let symbol = unit.symbol

        if (unit.symbols) {
            let _multiplier = 1
            const symbols = Object.values(unit.symbols)
            if (fromSymbol) {
                const index = symbols.findIndex(item => item[0] === fromSymbol)
                if (index >= 0) {
                    _multiplier = Math.pow(1e3, index)
                    symbols.splice(0, index)
                }
            }
            symbol = symbols[0][0]
            for (const [s, divider] of symbols.reverse()) {
                if (debug) {
                    console.table({
                        unitId,
                        s,
                        fromSymbol,
                        value,
                        divider,
                        _multiplier,
                    })
                    console.log('symbols: ', symbols)
                }
                if (value > divider / _multiplier) {
                    value /= divider / _multiplier
                    symbol = s
                    break
                }
            }
        }

        return {
            value: n(value, precision),
            symbol,
        }
    }

    valueFromAggregates(aggregates) {
        let aggregate = {}
        switch (true) {
            case ['PF', 'GCAL'].includes(this.param):
                aggregate = aggregates?.avg
                break
            case ['S', 'Q'].includes(this.param):
                aggregate = aggregates?.max
                break
            case ['C', 'U_CO2E', 'E', 'RE', 'U_GENE', 'GVOL'].includes(this.param):
                aggregate = aggregates?.sum
                break
        }

        return get(aggregate, `${this.param}.value`, null)
    }

    static allParameters(commodity = null, parentsOnly = true, tags = []) {
        let q = Unit.query()
        if (parentsOnly) {
            q = q.where('parentKey', null)
        }
        if (commodity) {
            q = q.where('commodity', e => e.includes(commodity))
        }
        if (tags?.length) {
            q = q.where('tags', e => {
                return intersection(e, tags).length
            })
        }
        return orderBy(q.get(), 'order')
    }

    static aggregatesPayload(units = []) {
        return units.reduce((acc, value) => {
            value.aggregates.forEach(a => {
                if (!acc[a]) {
                    acc[a] = []
                }
                acc[a].push(value.param)
            })
            return acc
        }, {})
    }

    get label() {
        return this.name ?? this.description
    }

    get isInstant() {
        return this.type === Unit.INSTANT
    }

    get isCumulative() {
        return this.type === Unit.CUMULATIVE
    }

    get children() {
        this.#children = this.#children || Unit.query().where('parentKey', this.key).get()
        return this.#children
    }

    get parent() {
        if (!this.parentKey) return
        this.#parent = this.#parent || Unit.query().where('key', this.parentKey).first()
        return this.#parent
    }

    get multiplier() {
        return this._multiplier || this.parent?.multiplier || 1
    }

    get units() {
        return this.symbols || this.parent?.symbols
    }

    get isCost() {
        return this.tags.includes('cost')
    }

    get symbol() {
        return this.unit
    }

    get order() {
        const o = Unit.orderMap.indexOf(this.key)
        return o >= 0 ? o : 9999
    }
}
