import { map, pick } from 'lodash'
import moment from '~/composables/useMoment'

export const actions = {
    downloadAnnotationsReport({ state, getters, rootState, rootGetters }) {
        const selectedClampsId = rootGetters['clamp/selectedClamps'].map(e => e.id)
        const rows = map(getters.all().filter(e => {
            return selectedClampsId.includes(e.circuitId) && moment(e.dt).isBetween(rootState.period.start, rootState.period.end)
        }), e => {
            return map(pick(e, ['dt', 'text', 'circuitName']), (e, k) => {
                if (k === 'dt') {
                    return moment(e).format('HH:mm DD-MM-YYYY')
                } else {
                    return e
                }
            })
        })
        if (rows.length === 0 || typeof document === 'undefined') {
            return false
        }
        // Timestamp,Note Information,Power (kW),Circuit Name,parameter
        rows.unshift(['Timestamp', 'Note Information', 'Circuit Name'])
        const csvContent = `data:text/csv;charset=utf-8,${rows.map(e => e.join(',')).join('\n')}`
        const encodedUri = encodeURI(csvContent)
        const link = document.createElement('a')
        link.setAttribute('href', encodedUri)
        link.setAttribute('download', `notes_${moment().format('YYYY_MM_DD_HH_mm_ss')}.csv`)
        document.body.appendChild(link) // Required for FF
        link.click() // This will download the data file named 'my_data.csv'.
        return true
    },
}
