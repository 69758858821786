export default {
    isChartOnRightSide(state) {
        return state.chartOnRightSide
    },
    treeviewItems(state) {
        return state.treeviewItems
    },
    isDialogWaiting(state) {
        return state.dialogWaiting
    },
    isEntityFetched: state => entity => {
        return state.loader.loadItems[entity] || false
    },
}
