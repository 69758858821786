import { FROM_ROOT } from '@/utils/state'
import { ALERTS_BY_COMMODITY } from '~/utils/commodities'

export default {
    async init({ commit, dispatch, rootState }, { forced = false } = {}) {
        // init cache
        await this.$cache.init()
        await Promise.all([
            ...ALERTS_BY_COMMODITY.map(c => dispatch('entities/clamps/setup', { commodity: c }, FROM_ROOT)),
            ...ALERTS_BY_COMMODITY.map(c => dispatch('entities/clamps/setupVirtual', { commodity: c }, FROM_ROOT)),
            this.$PeriodService.updatePeriodWithDebounce({}, p => dispatch('period/init', p, FROM_ROOT)),
        ])
        // update period resolution
        if (rootState.period.resolution !== 1800) {
            this.$PeriodService.updatePeriodWithDebounce({ resolution: 1800 }, p => dispatch('period/update', p, FROM_ROOT))
        }
        commit('INIT')
    },
}
