/* Models */
import { Model } from '@vuex-orm/core'
import reduce from 'lodash/reduce'
import Customer from './Customer'

/* Utils */
import Meter from '~/orm/models/Meter'
import { knownCommodities } from '~/utils/commodities'
import { extraColors } from '~/config/colors'

export default class Site extends Model {
    static entity = 'sites'
    // static primaryKey = 'siteId'

    static apiConfig = {
        dataKey: 'data',
        params: {
            includeUserGenerated: true,
        },
        actions: {
            addOrUpdateSite(site, createAsset = false) {
                if (site.id) {
                    return this.updateSite(site)
                } else {
                    return this.addSite(site, createAsset)
                }
            },
            addSite(site, createAsset = false) {
                return this.post('/onboarding/sites/with-asset', { site, createAsset }, {
                    baseURL: '/',
                    dataTransformer: ({ data: { site } }) => site,
                }).then(({ response: { data } }) => data)
            },
            updateSite(site) {
                return this.put(`/sites/${site.id}`, site, { dataTransformer: ({ data }) => data }).then(({ response: { data } }) => ({ site: data }))
            },
            deleteSite(siteId, assetIds = []) {
                return this.delete(`/onboarding/sites/${siteId}`, {
                    dataKey: null,
                    baseURL: '/',
                    params: { assetIds },
                }, { delete: siteId })
            },
        },
    }

    static fields() {
        return {
            id: this.attr(null),
            icon: this.attr('$building'),
            customerId: this.attr(null),
            name: this.attr(''),
            friendlyName: this.attr(''),
            presetId: this.attr(null),
            // metas: this.morphMany(Meta, 'entity_id', 'entity_name'),
            meters: this.hasMany(Meter, 'siteId'),
            customer: this.belongsTo(Customer, 'customerId'),
            editable: this.boolean(true),
            address1: this.attr(null),
            address2: this.attr(null),
            city: this.attr(null),
            postCode: this.attr(null),
            details: this.attr({}),
            realName: this.attr(null),
            latitudeLongitude: this.attr(null),
            size: this.attr(null),
            address: this.attr({}),
            isUserGenerated: this.attr(false),
            country: this.attr({}),
            color: this.attr(null),
            backupColor: this.attr(null),
            electricityTotalKw: this.number(0),
            gasTotalKw: this.number(0),
            waterTotalKw: this.number(0),
        }
    }

    static i = 0
    static colorIndex = 0
    static colors = []

    get siteId() {
        return this.id
    }

    static beforeCreate(model) {
        if (!model.details) {
            model.details = {}
        }
        if (model.$store().$cookie.get('anon')) {
            model.realName = model.name
            model.name = `Site ${++Site.i}`
        }

        if (!Site.colors.length) Site.colors = extraColors(model.$store().getters.theme).mainEntityColors
        if (Site.colorIndex >= Site.colors.length) Site.colorIndex = 0

        model.color = model.color || Site.colors[Site.colorIndex]
        model.backupColor = Site.colors[Site.colorIndex++]
    }

    get position() {
        if (this.latitudeLongitude) {
            const position = this.latitudeLongitude.split(',')
            return { lat: Number(position[0]), lng: Number(position[1]) }
        } else {
            return {
                lat: 0,
                lng: 0,
            }
        }
    }

    get location() {
        return this.position
    }

    get entity() {
        return Site.entity
    }

    get fullAddress() {
        return `${this.name}, ${this.address1} ${this.address2} ${this.city} ${this.postCode}`
    }

    get children() {
        let children = this.meters
        if (children.length === 0) {
            children = this.getChildren()
        }
        return children
    }

    /**
     * Get all relationship mlodels
     */
    get relationships() {
        // Get Meters by commodity
        const metersByCommodity = Object.fromEntries(knownCommodities.map(c => {
            return [`${c}Meters`, Meter.query()
                .where('siteId', this.id)
                .with('clamps')
                .where('commodity', c)
                .get()]
        }))
        // Get Clamps by Commodity
        const clampsByCommodity = Object.fromEntries(knownCommodities.map(c => [
            `${c}Clamps`,
            reduce(metersByCommodity[`${c}Meters`], (reducedClamps, meter) => {
                reducedClamps.push(...meter.clamps)
                return reducedClamps
            }, []),
        ]))

        return {
            ...metersByCommodity,
            ...clampsByCommodity,
        }
    }

    getName() {
        return this.name
    }

    getChildren(scope = '') {
        if (scope) {
            return Meter.query().where('siteId', this.id).where('commodity', scope).get()
        } else {
            return Meter.query().where('siteId', this.id).get()
        }
    }

    getUnitData(unit, resolution) {
        if (!unit) {
            console.warn('Unit is required')
            return
        }
        if (!this.id) {
            console.warn('Site ID is missing for some alien reason.')
            return
        }
        return this.$store().$cache.getUnitData(unit, this, this.commodity, resolution)
    }

    supportsUnit(unit) {
        return unit.manufacturers.some(m => (this.commodity ? this.relationships[`${this.commodity}Clamps`] : Object.values(this.relationships)).every(c => c.supportsUnit && c.supportsUnit(unit)))
    }

    get commodity() {
        return this.$store().state.clamp.commodity
    }

    /**
     * JSON serializer
     * responsible to define what data will be serialized.
     * @return {Object} json
     */
    toJSON() {
        return {
            ...this.$toJson(),
            id: this.id,
            entity: this.entity,
            children: this.children,
            name: this.getName(),
        }
    }
}
