import { validationMixin } from 'vuelidate'
import { minLength, maxLength, required, sameAs } from 'vuelidate/lib/validators'
import { validatePassword } from '~/utils/validation'

export default {
    mixins: [validationMixin],
    data: () => {
        return {
            password: {
                password: '',
                passwordConfirm: '',
                oldPassword: '',
            },
            changePasswordBtnDisabled: false,
            errorMessages: null,
            showSuccessMsg: false,
        }
    },
    validations: {
        password: {
            oldPassword: {
                required,
            },
            password: {
                required,
                minLength: minLength(12),
                maxLength: maxLength(50),
            },
            passwordConfirm: {
                required,
                sameAsPassword: sameAs('password'),
            },
        },
    },
    computed: {
        passwordErrors() {
            const errors = []
            if (!this.$v.password.password.$dirty) return errors
            !this.$v.password.password.required && errors.push(this.$t('validation.password_required'))
            !this.$v.password.password.minLength && errors.push(this.$t('validation.password_min_length'))
            !this.$v.password.password.maxLength && errors.push(this.$t('validation.password_max_length'))
            return errors
        },
    },
    watch: {
        password: {
            deep: true,
            handler() {
                this.errorMessages = null
            },
        },
    },
    methods: {
        changePassword() {
            if (!this.password.oldPassword || this.password.oldPassword === '') {
                this.errorMessages = this.errorMessages || {}
                this.errorMessages.oldPassword = 'You must insert your current password'
            }

            if (!this.password.password || this.password.password === '') {
                this.errorMessages = this.errorMessages || {}
                this.errorMessages.password = 'You must insert a new password'
            }

            if (!this.password.passwordConfirm || this.password.passwordConfirm === '') {
                this.errorMessages = this.errorMessages || {}
                this.errorMessages.passwordConfirm = 'You must insert your current password'
            } else if (this.password.passwordConfirm !== this.password.password) {
                this.errorMessages = this.errorMessages || {}
                this.errorMessages.passwordConfirm = 'The new password has to match'
            }

            if (this.password.oldPassword !== '' && this.password.oldPassword === this.password.password) {
                this.errorMessages = this.errorMessages || {}
                this.errorMessages.password = 'The new password and the old password cannot be the same'
            }

            if (!validatePassword(this.password.password)) {
                this.errorMessages = this.errorMessages || {}
                this.errorMessages.password = `${this.$t('validation.the_password_rules')}
                    - ${this.$t('validation.at_least_one_capital')}
                    - ${this.$t('validation.at_least_one_number')}
                    - ${this.$t('validation.at_least_one_symbol')}
                    - ${this.$t('validation.minimum_character_password')}
                    - ${this.$t('validation.maximum_character_password')}`
            }
            if (this.errorMessages) return
            return this.$axios
                .post(this.$auth.strategies.local.options.endpoints.changePassword.url, {
                    ...this.password,
                })
                .then(() => {
                    this.showSuccessMsg = true
                })
                .catch(e => {
                    this.errorMessages = { oldPassword: e.response.data.message }
                })
                .finally(() => {
                    this.changePasswordBtnDisabled = false
                })
        },
    },
}
